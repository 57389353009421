import { NoResultContainer } from './styles/NoResult.style';

export default function NoResult({
  NoResultImg,
  noResultMsg,
  noResultAction,
  noResultActionMsg,
  fullHeight,
  noMargin,
}) {
  return (
    <NoResultContainer fullHeight={fullHeight} noMargin={noMargin}>
      {/* <NoResultContainer.Img
        src={NoResultMsg}
        alt='No result'
      ></NoResultContainer.Img> */}
      <NoResultImg />
      <NoResultContainer.Text>{noResultMsg}</NoResultContainer.Text>
      {noResultAction && (
        <NoResultContainer.Button className='btn' onClick={noResultAction}>
          {noResultActionMsg}
        </NoResultContainer.Button>
      )}
    </NoResultContainer>
  );
}
