import styled, { keyframes } from 'styled-components';

export const SkeletonContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

export const BaseWrapper = styled.div`
  background: #ddd;
  margin: 10px 0;
  border-radius: 4px;
`;

BaseWrapper.Text = styled(BaseWrapper)`
  margin: ${(props) => (props.margin ? props.margin : '2px 0')};
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '10px')};
  text-align: ${(props) => props.textAlign && props.textAlign};
`;

BaseWrapper.Title = styled(BaseWrapper)`
  margin: ${(props) => (props.margin ? props.margin : '2px 0')};
  width: ${(props) => (props.width ? props.width : '100%')};
  max-width: ${(props) => props.maxWidth && props.maxWidth};
  height: ${(props) => (props.height ? props.height : '20px')};
`;

BaseWrapper.Logo = styled(BaseWrapper)`
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

BaseWrapper.Container = styled(BaseWrapper)`
  width: ${(props) => (props.width ? props.width : '300px')};
  height: ${(props) => (props.height ? props.height : '100px')};
  border-radius: ${(props) => props.borderRadius && props.borderRadius};
  margin: ${(props) => props.margin && props.margin};
  min-height: ${(props) => props.minHeight && props.minHeight};
  position: ${(props) => props.position && props.position};
  flex: ${(props) => props.flex && props.flex};
  overflow-x: ${(props) => props.overflowX && props.overflowX};
  opacity: ${(props) => props.opacity && props.opacity};
`;

BaseWrapper.CheckoutFooter = styled(BaseWrapper)`
  width: 100%;
  max-width: 500px;
  height: 65px;
  position: fixed;
  bottom: 0;
  border-radius: ${(props) => props.borderRadius && props.borderRadius};
  margin: ${(props) => props.margin && props.margin};
`;

BaseWrapper.Header = styled(BaseWrapper)`
  position: fixed;
  width: 100%;
  height: 65px;
  max-width: 500px;
  top: 0;
  margin: ${(props) => props.margin && props.margin};
`;

const runner = keyframes`
    0% {
        transform : translateX(-70%) 
    }
    // 50% {
    //     transform : translateX(-30%) 
    // }
    100% {
        transform : translateX(130%) 
    }
`;

export const ShimmerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: ${runner} 1s infinite;
`;

ShimmerWrapper.Shimmer = styled.div`
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: skewX(-10deg);
`;
