import styled from 'styled-components';
import FooterImage from '../../../assets/images/footer-banner-1.svg';
export const HomScreenContainer = styled.div`
  position: relative;
  max-width: 500px;
  margin: 0 auto;
`;
export const ProductContainer = styled.div`
  position: relative;
  max-width: 500px;
  margin: 0 auto;
  max-height: 100vh;
  overflow: hidden;
  // background-color: #f5f5f5;
`;
export const CategoriesWrapper = styled.div`
  position: relative;
  padding-right: 10px;
  padding-left: 10px;
  height: calc(100vh - 70px);
  overflow-y: auto;
  padding-bottom: 50px;
  ${({ inProductPage }) => !inProductPage && `margin-top: 60px;`}
  background-color: #FFFFFF;
  transition: opacity 0.2s ease-in-out;
  ${({ isMenuOpen, searchProducts }) =>
    (isMenuOpen || searchProducts) &&
    `pointer-events: none; opacity:0.5; transition: opacity 0.2s ease-in-out;`}
  @supports (-webkit-touch-callout: none) {
    padding: 13px 13px 110px 13px;
  }
`;
export const Recenthead = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 15px 0;
  padding-top: 4%;
  padding-left: 2%;
  line-height: 27px;
`;
export const CategoryBlock = styled.div`
  margin: 0;
  min-height: 0;
  ${({ inProductPage }) =>
    !inProductPage && `padding-bottom: 45px;background:white;`}
  ${({ inProductPage }) => inProductPage && `margin-top:11%;`}
`;
CategoryBlock.H2 = styled.h2`
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 15px 0;
`;
CategoryBlock.H3 = styled.h2`
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 15px 0;
  line-height: 27px;
`;
CategoryBlock.H4 = styled.h2`
  font-size: 16px;
  font-weight: 700;
  color: #000;
  margin: 16px 0;
  max-width: 284px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
CategoryBlock.SearchBar = styled.button`
  ${({ inProductPage }) =>
    inProductPage
      ? `background : white`
      : `background: var(--list-background-color) 0% 0% no-repeat padding-box;`}
  height: 49px;
  border-radius: 8px;
  width: 99%;
  margin: 11px 0;
  cursor: text !important;
  padding: 5px 14px;
  outline: none;
  border: none;
  text-align: left;
  color: var(--color-grey);
  .icon-search-home {
    width: 20px;
    height: 30px;
    opacity: 0.55;
    stroke: var(--color-grey);
  }
  span {
    width: 90%;
    padding: 10px;
    margin-left: -5px;
    font-size: 14px;
    font-weight: var(--font-weight-500);
  }
`;
export const SubCategoryBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  height: 120px;
`;
SubCategoryBlock.h2 = styled.h2`
  color: ${(props) => props?.restaurantColor || '#343434'};
  font-size: 1rem;
  font-weight: 900;
  margin: 0;
  text-transform: uppercase;
  border-bottom: 2px solid ${(props) => props?.restaurantColor || '#3C4B64'};
  display: inline-block;
  padding-bottom: 2px;
`;
SubCategoryBlock.div = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const MenuItemContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr));
  text-align: center;
  grid-column-gap: 0.8rem;
  grid-row-gap: 50px;
  padding-bottom: 10px;
`;
export const MenuButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  margin: 0 auto 5vh;
  background: ${(props) => props?.restaurantColor || '#D2202E'};
  color: var(--default-button-text-color);
  font-size: 15px;
  font-weight: var(--font-weight-500);
  position: fixed;
  bottom: 40px;
  left: 0;
  right: 0;
  padding-left: 11px;
  border-radius: 17px;
  cursor: pointer;
  border: none;
  outline: none;
  > img {
    height: 33px;
  }
`;
export const LDSDualRing = styled.div`
  content: ' ';
  left: 0;
  right: 0;
  margin: 90px auto;
  position: absolute;
  z-index: 1;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border-width: 7px;
  border-style: solid;
  border-color: #414141 transparent #414141 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export const GIPBotIFrame = styled.iframe`
  width: 100%;
  max-width: 500px;
  margin: 60px auto auto;
  height: calc(100% - 60px);
  border: none;
  display: flex;
  position: fixed;
  z-index: 9;
  bottom: 0px;
  left: 0px;
  right: 0px;
`;
export const CountContainer = styled.div`
  position: relative;
`;
CountContainer.Div = styled.div`
  display: flex;
  width: 75px;
  height: 30px;
  border: 1px solid #a2a2a2;
  border-radius: 18px;
  position: relative;
`;
export const CountMinus = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 700 !important;
  width: 22px;
  cursor: pointer;
  border-radius: 18px 0 0 18px;
`;
export const CountPlus = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 700 !important;
  color: var(--default-theme-color);
  width: 22px;
  border-radius: 0 18px 18px 0;
`;
export const CountBlock = styled.span`
  flex: 1 1;
  word-wrap: normal;
  border-radius: 0;
  margin: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 700 !important;
`;
export const AddButtonBlock = styled.div`
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: flex-end;
  align-items: center;
  align-content: space-around;
`;
AddButtonBlock.Span = styled.span`
  // position: absolute;
  // left: 50%;
  // transform: translateX(-50%);
  // font-size: 10px;
  // color: #727272 !important;
  font-size: 11px;
  font-weight: 600;
`;
AddButtonBlock.Button = styled.button`
  // background-color: var(--default-theme-color);
  // color: var(--default-button-text-color);
  padding: 10px 12px;
  border: 1px solid var(--default-theme-color);
  color: var(--default-theme-color);
  display: flex;
  background: transparent;
  align-items: center;
  justify-content: center;
  // width: 75px;
  width: 66px;
  height: 30px;
  // text-transform: uppercase;
  // border-radius: 18px;
  border-radius: 7px;
  min-width: 0;
  // font-size: 13px;
  // font-weight: 700 !important;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  // border: 1px solid #A2A2A2;
  &:hover {
    background-color: var(--default-theme-color);
    color: var(--default-button-text-color);
    cursor: pointer;
  }
`;
export const EmptyCart = styled.div`
  width: 100%;
  height: calc(100% - 120px);
  display: flex;
  align-items: center;
  justify-content: center;
`;
EmptyCart.img = styled.img`
  display: block;
  width: 100px;
  height: 100px;
  opacity: 0.5;
  margin: auto;
`;
EmptyCart.span = styled.span`
  font-size: 14px;
  color: #888888;
  text-align: center;
`;
export const CustomizableText = styled.span`
  font-size: 8px;
  color: var(--color-grey);
  margin-top: 2px;
  font-weight: 500;
`;
export const ProductDetails = styled.div`
  background-color: white;
  // height: 25%;
  width: 100%;
  .prod-details {
    color: #343434;
    font-weight: 700;
    font-style: normal;
    opacity: 1;
    margin: 10px 5px;
  }
`;
ProductDetails.Description = styled.div`
  opacity: 0.6;
  word-break: break-word;
  font-size: 14px;
`;
ProductDetails.SimilarProducts = styled.div`
  width: 45%;
  /* height: 77%; */
  /* bottom: 0%; */
  border: 1px solid grey;
  border-radius: 12px;
  margin-left: 5%;
`;
ProductDetails.ProductImage = styled.div`
  background: url(${(props) => props.url}) no-repeat padding-box;
  border-radius: 12px 0px 0px 12px;
  background-position: center;
  /* background-size: cover; */
  width: 100%;
  height: 167px;
`;
export const HorizontalBlock = styled.div`
  display: flex;
  flex-direction: row;
  margin: '0.1em';
`;
export const ProductHorizontalView = styled.div`
  // padding: 0 10px;
  display: flex;
  flex-direction: row;
`;
export const s = styled.button`
  padding: 10px 12px;
  border: 1px solid var(--default-theme-color);
  color: var(--default-theme-color);
  display: flex;
  background: transparent;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  border-radius: 7px;
  min-width: 0;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    background-color: var(--default-theme-color);
    color: var(--default-button-text-color);
    cursor: pointer;
  }
`;
export const CatNames = styled.div`
-webkit-column-count: 1;
    -webkit-column-width: 150px;
    color: #161616;
    font-size: 12px;
    font-family: poppins;
    padding: 5px 5px;
    // font-weight: 600;
    column-gap:0px;
    // margin-bottom:10%;
    // @media (max-width: 375px) {
    //   margin-bottom: 7%;
    // }

    .catnames {
      padding: 5px 2px;
      cursor: pointer;
      font-size: 11px;
      font-weight: 500;
    }
}
`;
export const ViewContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const SpanBlock = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  color: ${({ restaurantColor }) =>
    restaurantColor ? restaurantColor : '#ab5252'};
  margin-top: 1em;
  cursor: pointer;
  padding-right: 1.5em;
`;

export const ConsumerFooter = styled.div`
  background: #faf5ff;
  font-size: 13px;
  margin-bottom: 7%;

  .EnclosedContainer {
    width: 100%;
    background: #faf5ff;
    // height: 35vh;
    border-radius: 10px;
    background-image: url(${FooterImage});
    // background-size: cover;
    background-repeat: no-repeat;
    // margin-bottom: 7%;
    padding: 10px;
    display: flex;
    position: relative;
    background-position-x: right;
    background-position-y: center;
    min-height: 240px;
  }

  .year {
    font-size: 12px;
    font-family: poppins;
    font-weight: 500;

    // bottom: 2px;
  }
  .gupshup {
    font-size: 12px;
    font-family: poppins;
    font-weight: 500;

    // right: 15px;
    // bottom: 2px;
  }

  .gupshupLogo {
    height: auto;
    max-width: 72px;
  }

  .Bottom {
    padding: 5px 15px 10px 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #161616;
    // justify-content: space-between;
    // margin-top: 32%;

    // @media screen and (max-width: 320px) {
    //   margin-top: -4%;
    // }
  }

  .content {
    display: flex;
    align-items: center;
  }
`;

export const FlexSection = styled.div`
  display: flex;
  padding: 10px 15px;
  gap: 10px;
`;

export const LinkDiv = styled.div`
  cursor: pointer;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #161616;
`;
