import styled from 'styled-components';
import SplashBackground from '../../../assets/images/splashbackground.png';
import SplashStoreBg from '../../../assets/images/splashLogoBg.svg';
function getBoxShadow(props) {
  return props.active
    ? `0 0 0 2px #fff, 0 0 0 4px ${props.color ? props.color : '#EE2F37'};`
    : '0 0 0 2px #fff, 0 0 0 4px #cccccc;';
}

export const LPContainer = styled.div`
  position: relative;
  max-width: 500px;
  margin: 0 auto;
  height: 100vh;
  overflow: hidden;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TopPanel = styled.div`
  padding: 20px 0;
  /* min-height: 200px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
`;

export const LogoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
`;

LogoContainer.Logo = styled.img`
  max-width: 150px;
  max-height: 150px;
  min-width: 150px;
  min-height: 150px;
  border: 2px solid rgba(112, 112, 112, 0.1);
  border-radius: 5px;
`;
LogoContainer.Title = styled.span`
  white-space: nowrap;
  padding-top: 15px;
  text-overflow: ellipsis;
  max-width: 330px;
  overflow: hidden;
  font-size: 18px;
  color: var(--default-text-color);
  font-weight: var(--font-weight-500);
`;
export const RestaurantTitle = styled.div`
  font-size: 12px;
  font-weight: var(--font-weight-300);
  color: #0a0a0a;
  text-transform: capitalize;
  text-align: center;
  max-width: 200px;
  line-height: 1.3;
  overflow: hidden;
  position: relative;
  margin-top: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const ActionText = styled.p`
  color: #768192;
  text-align: center;
  font-size: 16px;
`;
ActionText.Type = styled.span`
  color: #000000;
  font-weight: bolder;
  text-transform: capitalize;
`;
export const MainPanel = styled.div`
  padding: 10px 20px;
`;

export const OptionCard = styled.div`
  height: 88px;
  padding: 25px;
  background: #f2f2f2;
  cursor: pointer;
  border-radius: 20px;
  border: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.active ? '#3A3A3A' : '#555555')};
  border: ${(props) =>
    props.active ? `2px solid var(--default-theme-color)` : 'none'};
  position: relative;
`;

OptionCard.Selected = styled.span`
  position: absolute;
  right: 11px;
  top: 10px;
  color: var(--default-theme-color);
`;
export const OutletLink = styled.a`
  text-align: center;
  display: block;
  font-size: 14px;
  margin: 7px;
  text-decoration: underline !important;
  color: ${(props) => (props.color ? props.color : '#009933')} !important;
`;
OptionCard.OptionImg = styled.div`
  width: 38px;
  height: 38px;
  margin-right: 11px;
  pointer-events: none;
`;

OptionCard.Img = styled.img`
  width: 100%;
  height: 100%;
`;

OptionCard.Text = styled.span`
  text-align: center;
  font-size: 16px;
  font-weight: var(--font-weight-700);
  color: #000000;
`;

export const ProceedButton = styled.button`
  color: var(--default-button-text-color);
  background-color: var(--default-theme-color);
  bottom: 0;
  border: none;
  padding: 14px 38px;
  border-radius: var(--default-button-border-radius);
  outline: none;
  transform: translateX(-50%);
  margin-left: 50%;
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    color: var(--default-button-text-color);
  }
`;

export const OfflineText = styled.div`
  position: relative;
  font-weight: 500;
  line-height: 40px;
  color: #7b7e87;
  padding-left: 20%;
  @media (min-width: 200px) and (max-width: 300px) {
    font-size: 15px;
    padding-left: 12%;
  }
`;

export const ViewStoreButton = styled.button`
  color: var(--default-button-text-color);
  background-color: var(--default-theme-color);
  bottom: 0;
  border: none;
  padding: 16px 38px;
  border-radius: var(--default-button-border-radius);
  outline: none;
  transform: translateX(-50%);
  position: relative;
  left: 53%;
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    color: var(--default-button-text-color);
  }
  @media (min-width: 200px) and (max-width: 300px) {
    font-size: 14px !important;
    padding: 16px 38px !important;
    left: 50%;
  }
`;

export const Checked = styled.span`
  width: 15px;
  height: 15px;
  margin-right: 5px;
  border-radius: 50%;
  background: var(--default-theme-color);
  color: var(--default-button-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SplashBgLogo = styled.div`
  background-image: url(${SplashBackground});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 210px;
  max-width: 375px;
  position: fixed;
  bottom: 0;
`;

export const SplashBgTitle = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 55%;
  bottom: auto;
  margin: 0 auto;
  font-size: 13px;
  line-height: 15px;
  color: #111;
  display: flex;
  justify-content: center;
`;

export const GifLoaderImage = styled.img`
  height: 90px;
  width: 90px;
  position: absolute;
  top: 55%;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: auto;
`;

export const Splashbg = styled.div`
  width: 100%;
  max-width: 204px;
  border: 2px solid #ab5252;
  min-height: 204px;
  position: relative;
  border-radius: 39px;
  margin: -60px 0 80px;
  box-shadow: 0px 4px 34px rgb(171 82 82 / 17%);

  &:after {
    content: '';
    position: absolute;
    background-image: url(${SplashStoreBg});
    background-repeat: no-repeat;
    background-size: contain;
    width: 310px;
    height: 324px;
    top: -70px;
    left: -50px;
    right: 0;
    bottom: auto;
  }
  &:before {
    content: '';
    position: absolute;
    background: #fff;
    width: 200px;
    height: 200px;
    z-index: 2;
    border-radius: 39px;
  }
`;

LogoContainer.SplashLogo = styled.img`
  position: absolute;
  top: 60px;
  left: 55px;
  max-width: 96px;
  width: 100%;
  min-height: 85px;
  z-index: 3;
`;

LogoContainer.SplashTitle = styled.span`
  white-space: nowrap;
  padding-top: 10px;
  text-overflow: ellipsis;
  max-width: 330px;
  overflow: hidden;
  font-size: 25px;
  line-height: 36px;
  font-weight: 500;
  color: var(--default-text-color);
  font-weight: var(--font-weight-500);
`;
