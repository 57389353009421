export const LANDING = '/:productId';
export const PROD_DESCRIPTION = '/:productId/:catId/:subId/:itemId';
export const HOMESCREEN = '/:productId/home';
export const SEARCHRESULT = '/:productId/search';
export const PRODUCT = '/:productId/products/:categoryId';
export const CHECKOUT = '/:productId/checkout';
export const VEIWADDRESS = '/:productId/viewAddress';
export const PROFILE = '/:productId/profile';
export const MYORDERS = '/:productId/myorders';
export const FAQ = '/:productId/faq';
export const TERMSANDCONDITIONS = '/:productId/TERMSANDCONDITIONS';
export const ORDER_SUMMARY = '/:productId/ordersummary';
export const VIEW_DEAL = '/:productId/viewDeal/:dealId';
export const ERROR404 = '/error/404';
export const ERROR500 = '/error/500';
export const PAYMENT = '/:productId/payment';
export const PAYMENT_D = '/payment';
export const CHECKOUT_D = '/checkout';
export const PRODUCT_D = '/menu';
export const HOME_D = '/home';
export const BASE = '/';
export const GUPSHUPHOME = 'https://www.gupshup.io/developer/gip/retail';
