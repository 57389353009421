import Currency from '../../Components/Currency/Currency';
import { offersMessage } from '../../utils/common';

function getCurrency() {
  return <Currency />;
}

export const getDescription = (offer) => {
  let desc = offersMessage(
    offer.discount_type,
    offer.max_discount,
    offer.min_order_value,
    offer.code,
    offer.discount_value,
    false,
    getCurrency
  );
  return desc;
};
