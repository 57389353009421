import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import './NavigationHeader.scss';
import { Header } from './styles/NavigationHeader.style';
import { ReactComponent as ChevronLeft } from '../../assets/icons/chevron-left.svg';

export default function NavigationHeader({
  title,
  logo,
  backClickEvent,
  ...props
}) {
  const logoImg = logo ? (
    <img className='logo' alt='Restaurant Logo' src={logo} />
  ) : null;
  const { productId } = useParams();
  const history = useHistory();

  const onBackClickEvent = () => {
    if (backClickEvent) {
      backClickEvent();
      return;
    }
    history.push(`/${productId}`);
  };

  return (
    <Header {...props}>
      <Header.BackButton onClick={onBackClickEvent}>
        <ChevronLeft size='24' strokeWidth='2' />
      </Header.BackButton>
      <Header.Title>{title}</Header.Title>
    </Header>
  );
}
