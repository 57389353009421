import { createGlobalStyle } from 'styled-components';
import './app.scss';

function getContrastYIQ(hexcolor, defaultColor) {
  hexcolor = hexcolor.replace('#', '');
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : defaultColor;
}
export const GlobalStyles = createGlobalStyle`

:root {

/* Colors: */
--default-theme-color: ${(props) =>
  props?.restaurant?.meta_data?.defaultThemeColor || '#B04040'};
--default-text-color: ${(props) =>
  props?.restaurant?.meta_data?.defaultTextColor || '#103675'};
--default-button-text-color: #FFFFFF;
--default-backdrop-color: ${(props) =>
  props?.restaurant?.meta_data?.defaultBackdropColor || '#F8CCCC'};;
--color-grey: #343434;
--color-ae5151: #AE5151;
--list-background-color: #f3f3f3;
--primary-background-color: #FFFFFF;
--instructions-added-icon-color: #5ACB10;
--default-button-border-radius: 6px;
--font-weight-100: 100;
--font-weight-300: 300;
--font-weight-500: 500;
--font-weight-700: 700;
// Open Sans
--default-font-family: 'poppins', sans-serif; 
--default-font-family-dashboard: 'poppins', sans-serif;
}
  *, *:before, *:after {
    box-sizing: border-box;
  }
  html, body {
    @supports (-webkit-touch-callout: none) {
      padding-bottom: 110px;
    }
    height: 100%;
    font-family: var(--default-font-family) !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #FFFFFF;
    color: #000000;
    font-size: 16px;
}
  .gmnoprint {
    display: none !important;
  }
  .Toastify__toast-container {
    padding: 10px;
  }
  .grecaptcha-badge {
    position: fixed !important;
    left: -1000px !important;
    z-index: -9;
  }
  .form-control:invalid, .form-control.is-invalid {
    background-image: none !important;
  }
  .addressModal {
    .modal-content {
      border-radius: 10px !important;

    }
  }
/*  .app {
    background-color: #f6f6f6;
  }
  
  @media( min-width: 500px ) {
    .App {
      box-shadow: 0 0 38px 7px rgba(0, 0, 0, 0.12);
    }
  }*/`;
