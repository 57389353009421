import React from 'react';
import { Spinner } from './styles/spinner';
import { Spinner as Loader } from 'react-bootstrap';
import { useState, useEffect, useContext } from 'react';
import { RestaurantsInfo } from '../../contexts/restaurantInfo';

export default function SpinnerComponent({ ...props }) {
  // const { restaurant, setRestaurantColor } = useConext(RestaurantsInfo);
  const restaurantColor = props.color ? props.color : '';
  return (
    <Spinner {...props}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Loader
          style={{ color: restaurantColor ? restaurantColor : `#B04040` }}
          animation='border'
        />
      </div>
      {props.message ? (
        <div style={{ color: restaurantColor ? restaurantColor : `#B04040` }}>
          <b>{props.message}</b>
        </div>
      ) : (
        ''
      )}
    </Spinner>
  );
}
