export const OrderStatus = Object.freeze({
  CONFIRMED: 'confirmed',
  COMPLETED: 'completed',
  ACK: 'acknowledged',
  ACKNOWLEDGED: 'ORDER_PLACED',
  ORDER_CREATED: 'ORDER_CREATED',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PAYMENT_CONFIRMED: 'PAYMENT_CONFIRMED',
  ACCEPTED_BY_RESTAURANT: 'ORDER_ACCEPTED',
  ACCEPTED_BY_DP: 'ACCEPTED_BY_DELIVERY_PARTNER',
  PREPARING_ORDER: 'ORDER_IN_PROCESS',
  READY_FOR_DISPATCH: 'READY_FOR_DISPATCH',
  ORDER_PICKED_UP: 'ORDER_DISPATCHED',
  DELIVERED: 'ORDER_DELIVERED',
  CANCELLED_BY_RESTAURANT: 'ORDER_CANCELLED',
  CANCELLED_BY_DP: 'CANCELLED_BY_DELIVERY_PARTNER',
  CANCELLED: 'USER_CANCELLED',
  READY_FOR_PICKUP: 'READY_FOR_PICKUP',
  ORDER_COMPLETED: 'ORDER_COMPLETED',
  WAITING: 'Waiting for delivery partner to accept the order',
});

export const OrderStatusDisplay = Object.freeze({
  CONFIRMED: 'Confirmed',
  COMPLETED: 'FulFilled',
  ACK: 'acknowledged',
  ACKNOWLEDGED: 'Order Placed',
  ORDER_CREATED: 'Order Placed',
  PAYMENT_PENDING: 'Payment Pending',
  PAYMENT_CONFIRMED: 'Payment Confirmed',
  ACCEPTED_BY_RESTAURANT: 'Confirmed',
  ACCEPTED_BY_DP: 'Confirmed',
  PREPARING_ORDER: 'Processing',
  READY_FOR_DISPATCH: 'Ready to dispatch',
  ORDER_PICKED_UP: 'Out for Delivery',
  ORDER_PICKED_UP_ECOM: 'Package is shipped',
  DELIVERED: 'Delivered',
  CANCELLED_BY_RESTAURANT: 'Cancelled',
  CANCELLED_BY_DP: 'Cancelled',
  CANCELLED: 'Cancelled',
  READY_FOR_PICKUP: 'Ready for PickUp',
  ORDER_COMPLETED: 'FulFilled',
  WAITING: 'Waiting for delivery partner to accept the order',
});

export const PaymentMode = Object.freeze({
  ONLINE: 'online',
  CASH: 'cash',
});
export const PaymentStatus = Object.freeze({
  PENDING: 'pending',
  PAID: 'paid',
});

export const OrderType = Object.freeze({
  DELIVERY: 'delivery',
  TAKEAWAY: 'takeaway',
});
export const OrderTypeLabel = Object.freeze({
  DELIVERY: 'Delivery',
  TAKEAWAY: 'Takeaway',
});
export const Color = Object.freeze({
  defaultColor: '#EE2F37',
});
export const ChargeTypes = Object.freeze({
  FIXED: 'fixed',
  PERCENTAGE: 'percentage',
});

export const DeliveryPartner = Object.freeze({
  SELF_DELIVERY: 'self_delivery',
});

export const filterType = Object.freeze({
  FOOD: 'Food',
  BAR: 'Bar',
  VEG: 'Veg',
  NONVEG: 'Non-Veg',
  EGG: 'Egg',
});
export const paymentStatus = Object.freeze({
  PENDING: 'pending',
  PAID: 'paid',
  REFUNDED: 'refunded',
});
export const routeNames = Object.freeze({
  DASHBOARDName: 'Delivery',
  PASTORDERName: 'Past Orders',
  OFFERSName: 'Offers',
  INVENTORYName: 'Inventory',
  SETTINGS: 'Settings',
  SIGNUP: 'Sign Up',
  TAKEAWAY_DASHBOARD: 'Takeaway',
  DELIVERY_DASHBOARD: 'Delivery',
  ANALYTICS: 'Analytics',
});
export const refundStatusMsg = Object.freeze({
  SUCCESS: 'Refund initiated for order',
  FAILURE:
    'Refund could not be completed. Please trigger refund manually from razorpay dashboard',
});

export const OfferTypes = Object.freeze({
  FLAT: 'flat_discount',
  PERCENT: 'percent',
  FREEDELIVERY: 'free_delivery',
  FREEPRODUCT: 'free_product',
});

export const DiscountTypes = Object.freeze({
  ORDERLVL: 'order_level',
  ITEMLVL: 'item_level',
});

export const defaultWaitingTimeForResendOTP = 60;

export const reCAPTCHA_site_key = '6LdVTUgbAAAAAKntFYRY9Y2NmcDq9K-YFRtUK5XH';

export const RESTAURANT_TYPE = {
  BRAND: 'brand',
  OUTLET: 'outlet',
};

export const FulfillmentTime = [
  '9:00-11:00',
  '11:00-13:00',
  '13:00-15:00',
  '15:00-17:00',
  '17:00-19:00',
  '19:00-21:00',
];

export const bankStructure = Object.freeze({
  name: '',
  contact: '',
  email: '',
  bank_details: {
    account_type: 'bank_account',
    bank_account: {
      account_number: '',
      ifsc: '',
      name: '',
    },
  },
});

export const upiStructure = Object.freeze({
  name: '',
  contact: '',
  email: '',
  upi_details: {
    account_type: 'vpa',
    vpa: {
      address: '',
    },
  },
});

export const COLOURS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

export const ANALYTIC_DASHBOARD = {
  PAYMENT_CHART: 'Payment Mode',
  FULFILLMENT_CHART: 'Fullfillment Mode',
  TOP_PERFORMING_ITEMS_CHART: 'Popular Items',
  ORDER_CHART: 'Number Of Orders',
};

export const BUSINESS_TYPE = {
  RETAIL: 'retail',
  ECOM: 'ecom',
};

export const MENUID_DOMAIN = 'menuDomain';

export const allPageRoutes = [
  'home',
  'menu',
  'checkout',
  'tableReservation',
  'viewAddress',
  'checkout',
  'order',
  'payment',
];
