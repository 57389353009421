import { GET, POST, PUT, DELETE } from '../lib/axios';
import { fgetUser } from '../lib/Firebase';

const getRestaurantDetails = (productId) => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/menu/${productId}/orderTypes`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

function getOutletDetails(productId, data) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${productId}/getOutlet`, data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

// const getMenuDetails = (productId) => {
//   return new Promise((resolve, reject) => {
//     GET(`ui/v1/menu/${productId}`)
//       .then((response) => resolve(response.data))
//       .catch((error) => {
//         reject(error);
//       });
//   });
// };

const getItemOnScroll = (
  productId,
  catId,
  subCatId,
  type,
  batch,
  itemSize,
  item_id
) => {
  return new Promise((resolve, reject) => {
    GET(
      `ui/v1/${productId}/items/${catId}/${
        subCatId ? subCatId : 1
      }/${type}/${batch}/${itemSize}${item_id ? `?item_id=${item_id}` : ''}`
    )
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
};

const getCategoryDetails = (productId) => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/menu/${productId}/categories`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
};

function getFirstTimeLogin(productId, userData) {
  return new Promise((resolve, reject) => {
    GET(
      `ui/v1/${productId}/${userData.phone_number?.value}/checkFirstTimeLogin`
    )
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
}

function getFirstTimeOrder(userData) {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${userData.phone_number.value}/checkFirstTimeOrder`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
}

async function UpdatePartialAcceptance(menu_id, order_id, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(
      `/restaurants/v1/${menu_id}/orders/${order_id}/updateEditedOrders`,
      data,
      header
    )
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function UpdateBot(menu_id, data) {
  return new Promise((resolve, reject) => {
    POST(`/ui/v1/${menu_id}/mapOrdertype`, data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

//catalogueFeature/v1/:info/:establishment_id/update

// https://asia-south1-dev-catalogue.cloudfunctions.net/ui/v1/:menu_id/updateOrderType
//catalogueFeature/v1/GS_TEST/inventory

const getAddressList = (business_type) => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${business_type}/getAllAddress`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

function addNewAddress(business_type, address) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${business_type}/addAddress`, address)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

function newOrderRequest(productId, order) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/order/${productId}`, order)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

function paymentSuccess(orderId, paymentData) {
  return new Promise((resolve, reject) => {
    POST(`ui/v2/order/${orderId}/paymentSuccess`, paymentData)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

function UpdateOrder(orderId, paymentData) {
  return new Promise((resolve, reject) => {
    POST(`ui/v2/updateOrder/${orderId}`, paymentData)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

function newOrderRequestCustom(productId, order) {
  return new Promise((resolve, reject) => {
    POST(`ui/v2/order/${productId}`, order, null, ['INVALID_FULFILLMENT_MODE'])
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

const fetchPreferredPaymentMethods = () => {
  return new Promise((resolve, reject) => {
    GET(`ui/v2/getSavedCars`, null, ['ALL'])
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

async function checkUpiPayment(order_id) {
  return new Promise((resolve, reject) => {
    GET(`ui/v2/${order_id}/checkUpiPayment`, null, ['ALL'])
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function getUpiLink(order_id, data) {
  return new Promise((resolve, reject) => {
    POST(`ui/v2/${order_id}/getUpiLink`, data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

function addDeviceDetails(deviceData) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/storeDeviceDetails`, deviceData)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

function payOnline(productId, orderId) {
  return new Promise((resolve, reject) => {
    const data = {
      callback_url: window.location.origin + productId,
    };
    POST(`ui/v1/order/${orderId}/paymentLink`, data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

function updateOnlinePaymentStatus(orderId, data) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/order/${orderId}/paymentStatus`, data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

function getQuoteData(locationData) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/checkDeliveryStatus`, locationData)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function getUserAddress(data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/getUserAddress`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}
async function getDeliveryPartner(data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/getDeliveryPartner`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function updateOrderType(menuId, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menuId}/updateOrderType`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function updateReminderStatus(menuId, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menuId}/updateReminderStatus`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

function cancelOrderByUser(orderId) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/cancelOrder/${orderId}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

function generateOTP({ phoneNumber, recaptchaToken }) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/otp/send/${phoneNumber}`, { recaptchaToken })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

function validateOTP({ phoneNumber, OTP }) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/otp/verify/${phoneNumber}`, { otp: OTP })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

const generateGipLink = (botname) => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/gipLink/${botname}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

const getUserStatus = (phoneNumber) => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${phoneNumber}/checkUserStatus`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

async function getPreviousOrderProducts(menuId, size) {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${menuId}/getPastItems/${size}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function getNewPicksProducts(menuId) {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/consumer/${menuId}/newlyAddedItems/0/20`)
      .then((res) =>
        resolve(
          res.data.map((item) => ({
            ...item,
            variant_groups: item.variant_groups
              ? item.variant_groups.map((group) => ({
                  ...group,
                  variants: group.variants.map((variant) => ({
                    ...variant,
                    ref_id: variant.ref_id[0],
                  })),
                }))
              : item.variant_groups,
          }))
        )
      )
      .catch((err) => reject(err));
  });
}

const getSuggestions = (
  est_id,
  search_query,
  batchSize = '10',
  pageNum = '0'
) => {
  return new Promise((resolve, reject) => {
    GET(
      `ui/v1/${est_id}/${pageNum}/${batchSize}/getItemsFromELK?item_name=${search_query}`
    )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

const getItemDesc = (est_id, attr, data) => {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/items/${est_id}/read?attribute=${attr}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

const getGipUser = () => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/getGipUser`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

async function loggedIn() {
  let header = await getAccessToken();
  if (header) {
    return new Promise((resolve, reject) => {
      GET(`ui/v1/catalogue/rocketlink`, header)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }
}

const loggedOut = () => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/catalogue/anonrocketlink`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

const markNotificationAsRead = (menu_id) => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${menu_id}/markNotificationAsRead`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

async function getOfferList(menuId) {
  var header = await getAccessToken();
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${menuId}/listOffers`, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}
async function updateOfferStatus(menu_id, offer_id, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menu_id}/updateOfferStatus/${offer_id}`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}
async function createOffer(menu_id, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menu_id}/createOffer`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}
async function listActiveOffers(menu_id, data) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menu_id}/listActiveOffers`, data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

function checkOfferAvailableStatus(menu_id, offer_id, data) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menu_id}/applyOffer/${offer_id}`, data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}
async function updateNotifiedOrderStatus(menu_id, offer_id) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    PUT(`ui/v1/${menu_id}/updateNotifiedOrderStatus/${offer_id}`, '', header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function updateProfile(data) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/storeUserProfileDetails`, data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function removeAddress(business_type, addressRef) {
  return new Promise((resolve, reject) => {
    DELETE(`ui/v1/${addressRef}/${business_type}/deleteUserAddressDetails`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function getProfileDetails() {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/getUserProfileDetails`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function getMyOrders(menu_id) {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${menu_id}/getMyOrders`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function getUserDeliveryAddress(business_type, addressRef) {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${addressRef}/${business_type}/getUserAddressDetails`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function getHelpFromChatBot(menu_id) {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${menu_id}/help`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function trackOrder(orderId) {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${orderId}/trackOrder`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}
async function updateCartDetails(productId, data) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${productId}/updateCartDetails`, data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function updateWholeCart(productId, data) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${productId}/updateWholeCart`, data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function getCartDetails(productId, origin = '') {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${productId}/getCartDetails`)
      .then((res) => {
        if (origin === 'standAloneCheckout') {
          if (res.data && res.data.cartDetails.items) {
            let items = res.data.cartDetails.items.map((value) => value.item);
            resolve({
              items: items,
              cartSubTotal: res.data.cartDetails.cartSubTotal,
            });
          } else {
            resolve({ items: [], cartSubTotal: '' });
          }
        } else {
          resolve(
            (res.data &&
              res.data.cartDetails.items &&
              res.data.cartDetails.items.map((value) => value.item)) ||
              []
          );
        }
      })
      .catch((err) => reject(err));
  });
}

async function deleteCartDetails(productId) {
  return new Promise((resolve, reject) => {
    DELETE(`ui/v1/${productId}/deleteCartDetails`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function getAccessToken() {
  let token = await fgetUser();
  let header = null;
  if (token) {
    header = { Authorization: 'Bearer ' + token };
  }
  return header;
}

const getDealItems = (productId, deal_id) => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${productId}/deals/${deal_id}/getDealItems`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
};

const getActiveDeals = (productId) => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${productId}/deals/activeDealList`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

async function abandedCardCheckout(data) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/abandedCheckout/timer`, data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

const getOrderDetails = (order_id) => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/consumer/orderdetails/${order_id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export {
  addNewAddress,
  // getMenuDetails,
  getItemDesc,
  UpdateBot,
  getAddressList,
  getOutletDetails,
  newOrderRequest,
  UpdateOrder,
  newOrderRequestCustom,
  paymentSuccess,
  fetchPreferredPaymentMethods,
  checkUpiPayment,
  getUpiLink,
  payOnline,
  updateOnlinePaymentStatus,
  getRestaurantDetails,
  generateOTP,
  validateOTP,
  getQuoteData,
  getUserAddress,
  getDeliveryPartner,
  updateOrderType,
  cancelOrderByUser,
  generateGipLink,
  getGipUser,
  markNotificationAsRead,
  getOfferList,
  updateOfferStatus,
  createOffer,
  listActiveOffers,
  checkOfferAvailableStatus,
  updateNotifiedOrderStatus,
  updateReminderStatus,
  UpdatePartialAcceptance,
  loggedOut,
  loggedIn,
  removeAddress,
  updateProfile,
  getProfileDetails,
  getMyOrders,
  getUserDeliveryAddress,
  trackOrder,
  getHelpFromChatBot,
  getCategoryDetails,
  getItemOnScroll,
  updateCartDetails,
  updateWholeCart,
  getCartDetails,
  deleteCartDetails,
  getSuggestions,
  getPreviousOrderProducts,
  addDeviceDetails,
  getActiveDeals,
  getDealItems,
  getUserStatus,
  getFirstTimeLogin,
  getFirstTimeOrder,
  abandedCardCheckout,
  getNewPicksProducts,
  getOrderDetails,
};
