import React, { useState, useEffect, useRef} from 'react';

export default function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    const handler = useRef(null);
    useEffect(
        () => {
            clearTimeout(handler.current);
            handler.current = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            return () => {
                clearTimeout(handler);
            };
        },
        [value]
    );

    return debouncedValue;
}