import { get } from 'lodash';
import { allPageRoutes } from '../constants/variables';

export const CT_EVENT_IDS = {
  ESTABLISHMENT_ID: 'Establishment ID',
  LOGIN_PUSH: 'LOGIN_PUSH',
  PROFILE_PUSH: 'PROFILE_PUSH',
  ORDER_TYPE_SELECTED: 'Order type selected',
  ORDER_TYPE_SWITCHED: 'Order type switched',
  LOCATION_ADDED: 'Location added',
  REQUEST_LOCATION: 'Request location',
  SELECT_DELIVERY_LOCATION: 'Select delivery location',
  IN_APP_NOTIFICATIONS: 'In-App notifications',
  BACK_BUTTONS_CLICKED: 'Back buttons pressed',
  SEARCH_INITIATED: 'Search initiated',
  FILTER_APPLIED: 'Filter applied',
  VISITED_SOCIAL_HANDLES: 'Visited social handles',
  ADDED_TO_CART: 'Added to cart',
  CUSTOMIZATION_SLIDER_OPENED: 'Customization slider opened',
  CUSTOMIZATION_OPENED: 'Customization modal opened',
  MENU_BUTTON_CLICKED: 'Menu button clicked',
  MENU_CATEGORY_SELECTED: 'Menu category selected',
  CHECKOUT_CLICKED: 'Checkout clicked',
  SIGN_UP: 'Sign up',
  NUMBER_CHANGED: 'Number changed',
  OTP_RESENT: 'OTP resent',
  OTP_VERIFICATION: 'OTP verification',
  ADDRESS_FROM_CHECKOUT: 'Address from checkout',
  ADDRESS_ADDED: 'New address added',
  ADDITIONAL_INS_CLICKED: 'Additional instructions click',
  REPITITIONS_MODAL_OPENED: 'Repititions Modal Opened',
  COUPONS_VIEWED: 'Coupons viewed',
  COUPON_APPLIED: 'Coupon applied',
  COUPON_REMOVED: 'Coupon removed',
  ONLINE_PAYMENT_INITIATED: 'Online payment choosen',
  OFFLINE_PAYMENT_INITIATED: 'Offline payment choosen',
  ORDER_PLACED: 'Charged',
  ITEM_MODIFIED: 'Item add button',
  APP_LAUNCHED: 'App launched',
  PAYMENT_METHOD_SELECTED: 'Payment method selected',
  INSTRUCTIONS_ADDED: 'Instructions added',
  EMPTY_CART: 'Landed on Cart is empty page',
  ORDER_PLACED: 'Order placed',
  MY_ORDERS_OPENED: 'My orders viewed',
  CHECK_ORDER: 'Check Order',
  TRACK_ORDER: 'Order tracked',
  ORDER_CANCELLED: 'Order cancelled',
  HELP_INITIATED: 'Help Initiated',
  LOGIN_POPUP_OPENED: 'Login/Signup popup opened',
  LOGIN_POPUP_CLOSED: 'Login/Signup popup closed',
  HAMBURGER_MENU_OPENED: 'Hamburger menu opened',
  PROFILE_SETTINGS_VIEWED: 'Profile settings viewed',
  SAVED_ADDRESS_DELETED: 'Saved address deleted',
  NAME_CHANGED: 'Name changed',
  OTP_SENT: 'Otp sent',
  PRODUCT_VIEWED: 'Product Viewed',
  CATEGORY_VIEWVED: 'Category Viewed',
  SUBCATEGORY_VIEWED: 'Subcategory Viewed',
  CHECKOUT_PAGE_VIEWED: 'Checkout page viewed',
  PRODUCT_REMOVED_CART: 'Product removed from cart',
  PRODUCT_QUANTITY_CHANGED: 'Product quantity changed',
  CHATBOT_OPENED: 'Chatbot opened',
  SEARCHED: 'Searched',
  HOMEPAGE_VIEWED: 'HomePage viewed',
  LOGIN: 'Login',
  SIGN_UP: 'Sign Up',
  LOCATION_SELECTED: 'Location selected',
  SAVED_ADDRESS_SELECTED: 'Saved address selected',
  PREFERRED_METHOD_CHOSEN: 'Preferred method Chosen',
  POPULAR_METHOD_CHOSEN: 'Popular method Chosen',
  UPI_CHOSEN: 'UPI Chosen',
  WALLET_CHOSEN: 'Wallet Chosen',
  'DEBIT/CREDIT_CARD_CHOSEN': 'Debit/credit card chosen',
  NETBANKING_CHOSEN: 'Netbanking Chosen',
  PAYMENT_PAGE_VIEWED: 'Payment page viewed',
  PAYMENT_INITIATED: 'Payment initiated',
  ORDER_PLACED_PAGE_VIEWED: 'Order placed page viewed',
  MAP_LOCATION_VIEWED: 'Map location viewed',
};

export const allPages = {
  HOME_PAGE: 'Home Page',
  SET_LOCATION_PAGE: 'Set location page',
  MENU_PAGE: 'Menu page',
  CHECKOUT_PAGE: 'Checkout page',
  ADD_NEW_ADDRESS: 'Add new address page',
  OUTLET_SELECTION_PAGE: 'Outlet selection page',
  TABLE_RESERVATION_PAGE: 'Table reservation page',
};

export const clevertapEventPush = ({ eventId = '', eventData = {} }) => {
  const isDevEnv = process.env.REACT_APP_ENVI === 'development';
  const currentUrl = new URL(window.location.href);
  const menuId = currentUrl?.pathname?.split('/')[1];
  const productId = allPageRoutes.includes(menuId)
    ? currentUrl.hostname
    : menuId;
  const cleverTapObj = get(window, 'clevertap');
  const profileObj = {
    Site: {
      Name: eventData.name || '',
      Phone: eventData.phone ? `+${eventData.phone}` : '',
    },
  };
  if (cleverTapObj) {
    if (eventId === CT_EVENT_IDS.LOGIN_PUSH) {
      cleverTapObj.onUserLogin.push(profileObj);
      isDevEnv && console.log('login push', profileObj);
    } else if (eventId === CT_EVENT_IDS.PROFILE_PUSH) {
      cleverTapObj.profile.push(profileObj);
      isDevEnv && console.log('profile push', profileObj);
    } else {
      isDevEnv &&
        console.log(eventId, {
          [CT_EVENT_IDS.ESTABLISHMENT_ID]: productId,
          ...eventData,
        });
      cleverTapObj.event.push(eventId, {
        [CT_EVENT_IDS.ESTABLISHMENT_ID]: productId,
        ...eventData,
      });
    }
  }
};
