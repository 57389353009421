import styled from 'styled-components';
export const ProductContainer = styled.div`
  position: relative;
  max-width: 500px;
  margin: 0 auto;
  max-height: 100vh;
  overflow: hidden;
  background-color: #f5f5f5;
`;
export const CategoriesWrapper = styled.div`
  position: relative;
  padding-right: 0px;
  height: ${(props) =>
    props?.searchbarActive ? 'calc(92vh - 0px)' : 'calc(92vh - 70px)'};
  overflow-y: ${(props) => props?.isScrollHidden || 'auto'};
  padding-bottom: 10px;
  ${({ inProductPage }) => !inProductPage && `margin-top: 60px;`}
  background-color: ${(props) =>
    props.isProductView ? 'transparent' : 'white'};
  ${(props) => props.marginTop && 'margin-top : 60px;'}
`;
export const CategoryBlock = styled.div`
  margin: 0;
  min-height: 0;
`;
CategoryBlock.Button = styled.button`
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: var(--font-weight-700);
  color: var(--color-grey);
  margin: 0 5px;
  height: 34px;
  min-width: 80px;
  max-width: 269px;
  background: ${(props) =>
    props.selected ? 'var(--default-backdrop-color)' : 'transparent'};

  border-radius: 21px;
  font-size: 13px;
`;
CategoryBlock.Div = styled.div`
  margin: 10px auto;
  padding: 4px 0;
  height: 42px;
  background: white;
  width: fit-content;
  border-radius: 21px;
  overflow: auto;
  white-space: nowrap;
  @media only screen and (max-width: 360px) {
    text-align: center;
  }
`;
export const SubCategoryBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 5px;
`;
SubCategoryBlock.div = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
SubCategoryBlock.h2 = styled.h2`
  color: ${(props) => props?.restaurantColor || '#3c4b64'};
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
  border-bottom: 2px solid ${(props) => props?.restaurantColor || '#3c4b64'};
  display: inline-block;
  padding-bottom: 2px;
`;
SubCategoryBlock.div = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const ProductFooter = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 500px;
  background: #fff;
  box-shadow: 0 2px 3px rgb(0 0 0 / 16%);
  z-index: 1;
`;
ProductFooter.Container = styled.div`
  padding: 10px 16px;
  background: ${(props) => props?.restaurantColor || '#444444'};
  color: #fff;
  display: block;
  width: 100%;
  font-weight: 600;
`;
ProductFooter.cartItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;
export const CartBag = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
CartBag.icon = styled.i`
  margin-right: 16px;
  font-size: 22px;
`;
export const TotalItemCounts = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #d91b12;
  text-align: center;
  position: absolute;
  left: 12px;
  top: -6px;
  font-size: 12px;
  line-height: 22px;
  font-weight: var(--font-weight-700);
`;
ProductFooter.Button = styled.button`
  background: ${(props) => props?.restaurantColor || '#ab0012'};
  display: flex;
  align-items: center;
  padding: 9px 16px;
  font-weight: 600;
  border: 1px solid #fff;
  border-radius: 6px;
  color: var(--default-button-text-color);
  &:hover {
    background: ${(props) => props?.restaurantColor || '#ab0012'};
    opacity: 1;
  }
`;
ProductFooter.Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: white;
  font-size: 17px;
`;
export const MenuItemContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  text-align: center;
  padding: 10px;
  grid-gap: 0.5rem;
`;
export const MenuButton1 = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  margin: 0 auto 5vh;
  background: ${(props) => props?.restaurantColor || '#D2202E'};
  color: var(--default-button-text-color);
  font-size: 15px;
  font-weight: var(--font-weight-500);
  position: fixed;
  bottom: 40px;
  left: 0;
  right: 0;
  padding-left: 11px;
  border-radius: 17px;
  cursor: pointer;
  border: none;
  outline: none;
  > img {
    height: 33px;
  }
`;
export const LDSDualRing = styled.div`
  content: ' ';
  left: 0;
  right: 0;
  margin: 90px auto;
  position: absolute;
  z-index: 1;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border-width: 7px;
  border-style: solid;
  border-color: #414141 transparent #414141 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export const GIPBotIFrame = styled.iframe`
  width: 100%;
  height: calc(100vh - 60px);
  border: none;
  display: ${(props) => (props.display ? 'block' : 'none')};
  position: relative;
  top: 60px;
  z-index: 9;
`;
export const CountContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const AddButtonBlock = styled.div`
  position: relative;
  text-align: center;
  width: 50%;
`;
export const CustomizableText = styled.span`
  font-size: 8px;
  color: var(--color-grey);
  margin-top: 2px;
  font-weight: 500;
`;
AddButtonBlock.Button = styled.button`
  padding: 10px;
  border: 1px solid var(--default-theme-color);
  color: var(--default-theme-color);
  display: flex;
  background: transparent;
  align-items: center;
  justify-content: center;
  width: 66px;
  height: 35px;
  text-transform: uppercase;
  border-radius: 7px;
  min-width: 0;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    color: var(--default-button-text-color);
    background-color: var(--default-theme-color);
    cursor: pointer;
  }
`;
export const EmptyCart = styled.div`
  width: 100%;
  height: calc(100% - 120px);
  display: flex;
  align-items: center;
  justify-content: center;
`;
EmptyCart.Img = styled.img`
  display: block;
  margin: auto;
`;
EmptyCart.Span = styled.span`
  font-size: 12px;
  color: #000000;
  font-weight: var(--font-weight-300);
  text-align: center;
`;

export const AddButtonWithoutPitcure = styled.div`
  position: relative;
  text-align: center;
  width: 50%;
  margin-top: -33px;
  margin-left: 80px;
  margin-bottom: 15px;
`;
AddButtonWithoutPitcure.Button = styled.button`
  padding: 10px;
  background-color: var(--default-theme-color);
  color: var(--default-button-text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  text-transform: uppercase;
  border-radius: 18px;
  min-width: 0;
  font-size: 13px;
  font-weight: var(--font-weight-700) !important;
  cursor: pointer;
  border: 1px solid #a2a2a2;
  &:hover {
    background-color: var(--default-theme-color);
    color: var(--default-button-text-color);
    cursor: pointer;
  }
`;

export const ProductListView = styled.div`
  padding: 0 10px;
  height: auto;
  width: 100%;
  margin-bottom: 40px;
  @media (max-width: 767px) {
    padding-bottom: ${(props) =>
      props.len ? (!props.len ? '120px' : '') : '120px'};
    margin-bottom: 0px;
  }
`;

export const ProductCount = styled.span`
  color: var(--color-grey);
  opacity: 0.5;
  font-size: 13px;
  font-weight: 500;
  display: inline-block;
  margin: 5px 0 10px 10px;
`;
export const MenuButton = styled.button`
  width: 94px;
  height: 45px;
  background: #f8cccc 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 18px;
  position: fixed;
  bottom: 85px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 12px;
  color: #b04040;
  font-size: 14px;
  font-weight: bold;
  outline: none;
  border: none;
`;

export const SearchBar = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  height: 49px;
  border-radius: 8px;
  width: 90%;
  margin: 11px auto;
  padding: 5px 14px;
  outline: none;
  border: none;
  text-align: left;
  display: flex;
  align-items: center;
  img {
    width: 20px;
    height: 30px;
    opacity: 0.55;
  }
  input {
    width: 88%;
    padding: 10px;
    background: transparent;
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none;
    outline: none;
    font-size: 14px;
    @supports (-webkit-touch-callout: none) {
      font-size: 16px;
    }
    font-weight: var(--font-weight-500);
    &:focus {
      border: none;
      outline: none;
    }
    &::placeholder {
      color: var(--color-grey);
    }
    @supports (-webkit-touch-callout: none) {
      font-size: 16px;
    }
  }

  span {
    width: 88%;
    padding: 10px;
    background: transparent;
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: var(--font-weight-500);
    &:focus {
      border: none;
      outline: none;
    }
    &::placeholder {
      color: var(--color-grey);
    }
    @supports (-webkit-touch-callout: none) {
      font-size: 16px;
    }
  }
`;

export const NormalDiv = styled.span``;
