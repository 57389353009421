import styled from 'styled-components';

export const AddressBookContainer = styled.div`
  position: relative;
  max-width: 500px;
  margin: 0 auto;
`;

export const GMAPContainer = styled.div`
  height: 100vh;
  max-height: 100vh;
  overflow: auto;
  display: flex;
  max-width: 500px;
  margin: 0 auto;
  flex-direction: column;
`;

export const AddressContainer = styled.div`
  z-index: 1;
  background: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0 20px 0;
`;

export const SavedAddresses = styled.div`
  width: 100%;
  padding-top: 8px;
  padding-left: 15px;
  padding-bottom: 15px;
`;

SavedAddresses.Title = styled.span`
  margin-left: 10px;
  font-size: 12px;
  font-weight: var(--font-weight-500);
  margin-bottom: 8px;
`;
SavedAddresses.Body = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-wrap: nowrap;

  @media only screen and (max-width: 400px) {
    justify-content: flex-start;
  }
`;

export const SavedAddress = styled.div`
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  max-width: 450px;
  padding: 16px 8px;
  background: #f8f8f8;
  border-radius: 12px;
  cursor: pointer;
  border: 1px solid
    ${(props) => (props.isSelected ? 'var(--default-theme-color)' : '#ffffff')};
  display: flex;
  @media only screen and (max-width: 400px) {
    max-width: 300px;
  }
`;

SavedAddress.Select = styled.div`
  color: var(--default-theme-color);
  display: flex;
  align-items: center;
  padding: 0 8px;
  cursor: pointer;
  justify-content: center;
  input {
    width: 15px;
    height: 15px;
  }
`;
SavedAddress.Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 9px;
  font-size: 12px;
  font-weight: var(--font-weight-500);
  width: 100%;
  span {
    margin-left: 9px;
  }
`;
SavedAddress.Address = styled.div`
  width: 100%;
  text-align: left;
  font-size: 12px;
  font-weight: var(--font-weight-400);
  height: 38px;
  overflow: hidden;
  opacity: 0.5;
`;
export const Button = styled.button`
  background-color: ${(props) => (props.color ? props.color : '#009933')};
  margin: 0 2.5%;
  padding: 10px 25px;
  border-radius: 4px;
  width: 95%;
  color: var(--default-button-text-color);
  font-weight: var(--font-weight-500);
  position: fixed;
  bottom: 10px;
`;

export const ButtonGroupLeft = styled.button`
  border-color: var(--default-theme-color);
  color: var(--default-theme-color);
  background: #ffffff;
  border-radius: var(--default-button-border-radius);
  margin-right: 8px;
  flex: 1;
  font-weight: var(--font-weight-500);
  font-size: 13px;
  :hover {
    color: var(--default-theme-color);
    box-shadow: none;
  }
`;

export const ButtonGroupRight = styled.button`
  background-color: var(--default-theme-color);
  border-radius: var(--default-button-border-radius);
  /* width: calc(50% - 10px); */
  flex: 1;
  color: #ffffff;
  font-weight: var(--font-weight-500);
  font-size: 13px;
  :hover {
    color: #ffffff;
    box-shadow: none;
  }
`;

export const AddressCard = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  border: 1px solid
    ${(props) =>
      props.selected ? (props.color ? props.color : '#e66158') : '#d8dbe0'};
  cursor: pointer;
`;

AddressCard.title = styled.p`
  font-size: 13px;
  font-weight: var(--font-weight-500);
`;

AddressCard.address = styled.p`
  font-size: 13px;
  font-weight: var(--font-weight-500);
  width: 60%;
`;
AddressCard.icon = styled.div`
  width: 20%;
`;
AddressCard.delete = styled.div`
  width: 20%;
`;

export const ContinueToDeliveryButton = styled.button`
  color: var(--default-button-text-color);
  background: var(--default-theme-color);
  padding: 10px;
  font-size: 14px;
  width: 60%;
  margin-top: ${(props) => (props.savedAddressPresent ? 0 : '15px')};
  font-weight: var(--font-weight-500);
  :hover {
    color: var(--default-button-text-color);
  }
  @media only screen and (max-width: 400px) {
    margin-top: 5px;
  }
`;

export const AddressInput = styled.input`
  background: var(--list-background-color) 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 12px 12px;
  font-size: 13px;
  font-weight: var(--font-weight-500);
  margin-bottom: 15px;
  width: 100%;
  outline: none;
  border: none;
  @supports (-webkit-touch-callout: none) {
    font-size: 16px;
  }
`;

export const AddressFormContainer = styled.div`
  width: 100%;
  padding: 15px;
  margin-bottom: 35px;
  .row {
    justify-content: center;
  }
  @media only screen and (max-width: 450px) {
    margin-bottom: 80px;
  }
`;

export const AddressList = styled.div`
  background: white;
  border-radius: 20px 20px 0 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  padding: 24px 10px;
  width: 100%;
`;

AddressList.Header = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: var(--font-weight-500);
  color: var(--color-grey);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

AddressList.SavedAddress = styled.div`
  padding: 0.6rem;
  margin-top: 12px;
  margin-bottom: 12px;
  overflow: auto;
  max-height: 35vh;
  display: flex;
  flex-wrap: wrap;
  ::-webkit-scrollbar {
    display: none;
  }
`;
export const AddressTypeContainer = styled.div`
  display: flex;
  align-items: center;
  @supports (-webkit-touch-callout: none) {
    padding-bottom: 70px;
  }
`;
AddressTypeContainer.Button = styled.div`
  color: var(--default-theme-color);
  background: ${(props) =>
    !props.isActive ? 'transparent' : 'var(--default-backdrop-color)'};
  border: ${(props) =>
    !props.isActive
      ? '1px solid var(--default-theme-color)'
      : '1px solid transparent'};
  border-radius: var(--default-button-border-radius);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  font-size: 12px;
  font-weight: var(--font-weight-500);
  margin: 0 10px;
  :hover {
    color: var(--default-theme-color);
  }
`;
AddressList.AddressContainer = styled.div`
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border: ${({ isActive }) =>
    isActive ? '1px solid var(--default-theme-color)' : 'none'};
  border-radius: 12px;
  padding: 23px 14px;
  width: 100%;
  margin: 9px 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
`;

AddressList.AddressName = styled.span`
  margin-left: 5px;
  font-size: 12px;
  font-weight: var(--font-weight-500);
  color: var(--color-grey);
`;
AddressList.FullAddress = styled.p`
  font-size: 12px;
  color: var(--color-grey);
  opacity: 0.5;
  font-weight: var(--font-weight-400);
  line-height: 13px;
  height: 26px;
  max-width: 300px;
  width: 100%;
  overflow: hidden;
  margin: 5px 8px;
`;

AddressList.SelectAddress = styled.button`
  outline: none;
  border: none;
  background: transparent;
  font-size: 12px;
  font-weight: var(--font-weight-500);
`;
export const Checked = styled.span`
  width: 15px;
  height: 15px;
  margin-right: 5px;
  border-radius: 50%;
  background: var(--default-theme-color);
  color: var(--default-button-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SelectedIcon = styled.span`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: white;
  background: var(--default-theme-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
`;

AddressContainer.FooterButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  padding: 15px;
  position: fixed;
  max-width: 485px;
  bottom: 0;
  background: white;
`;

AddressList.AddressTitle = styled.div`
  color: ${(props) =>
    props.isActive ? 'var(--default-theme-color)' : 'var(--color-grey)'};
`;
