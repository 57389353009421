import styled from 'styled-components';

export const GMAPContainer = styled.div`
  display: flex;
  padding: 0;
  height: 100%;
  margin-top: 60px;
  flex-direction: column;
`;

GMAPContainer.Map = styled.div`
  position: relative;
  overflow: hidden;
  min-height: ${({ isViewAddress, savedAddressPresent }) =>
    isViewAddress && savedAddressPresent ? '25vh' : '30vh'};
  flex: 2;
  width: 100%;
`;

GMAPContainer.Form = styled.div`
  z-index: 1;
  background: #ffffff;
  width: 100%;
  padding: 15px 15px 0 15px;
  border-radius: 21px 21px 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

GMAPContainer.Form.Title = styled.span`
  font-size: 14px;
  font-weight: var(--font-weight-700);
`;
GMAPContainer.Form.Button = styled.button`
  background: #ffeeee 0% 0% no-repeat padding-box;
  border: 2px solid
    ${({ restaurantColor }) => (restaurantColor ? restaurantColor : '#e83939')};
  border-radius: 10px;
  color: ${({ restaurantColor }) =>
    restaurantColor ? restaurantColor : '#e83939'};
  font-size: 14px;
  font-weight: var(--font-weight-700);
  display: flex;
  padding: 10px;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  svg {
    font-size: 19px;
    margin-right: 8px;
    color: ${({ restaurantColor }) =>
      restaurantColor ? restaurantColor : '#e83939'};
    margin-top: 0;
  }
  :hover {
    color: ${({ restaurantColor }) =>
      restaurantColor ? restaurantColor : '#e83939'};
  }
`;

export const Separator = styled.div`
  height: 1px;
  width: 90%;
  background-color: #d6d6d6;
  margin: 20px 0;
  position: relative;
`;

Separator.Text = styled.div`
  display: flex;
  position: absolute;
  top: -13px;
  left: 50%;
  margin-left: -15px;
  width: 25px;
  height: 25px;
  font-size: 14px;
  font-weight: var(--font-weight-700);
  align-items: center;
  justify-content: center;
  color: #000000;
  border-radius: 50%;
`;

GMAPContainer.Form.SearchBar = styled.div`
  background: var(--list-background-color) 0% 0% no-repeat padding-box;
  height: 49px;
  border-radius: 8px;
  width: 99%;
  padding: 5px 14px;
  outline: none;
  border: none;
  text-align: left;
  img {
    width: 20px;
    height: 30px;
    opacity: 0.55;
  }
  input {
    width: 90%;
    padding: 10px;
    background: transparent;
    margin-left: 5px;
    border: none;
    outline: none;
    font-size: 14px;
    @supports (-webkit-touch-callout: none) {
      font-size: 16px;
    }
    font-weight: var(--font-weight-500);
    &:focus {
      border: none;
      outline: none;
    }
    &::placeholder {
      color: var(--color-grey);
    }
  }
`;
