import React from 'react';
import {
  SkeletonContainer,
  BaseWrapper,
  ShimmerWrapper,
} from './Style/SkeletonLoader.style';
import {
  LPContainer,
  TopPanel,
  LogoContainer,
  MainPanel,
} from '../../pages/LandingPage/styles/LandingPage';

import {
  HomScreenContainer,
  ProductContainer,
  CategoriesWrapper,
  CategoryBlock,
  MenuItemContainer,
} from '../../pages/HomeScreen/styles/HomeStyle';
import { ProductListView } from '../../pages/ProductV2/styles/Product.style';
import {
  AddressBookContainer,
  GMAPContainer,
  SavedAddress,
  SavedAddresses,
} from '../../pages/AddressBook/styles/AddressBook';
import { OfferListContainer } from '../../Components/OfferList/styles/OfferList.style';

function Shimmer(props) {
  return (
    <ShimmerWrapper>
      <ShimmerWrapper.Shimmer></ShimmerWrapper.Shimmer>
    </ShimmerWrapper>
  );
}

export function LandingSkeleton(props) {
  return (
    <>
      <SkeletonContainer>
        <LPContainer>
          <TopPanel>
            <LogoContainer>
              <BaseWrapper.Logo></BaseWrapper.Logo>
            </LogoContainer>
            <BaseWrapper.Title height={'24px'}></BaseWrapper.Title>
            <BaseWrapper.Text width={'130%'}></BaseWrapper.Text>
            <BaseWrapper.Text></BaseWrapper.Text>
          </TopPanel>
          <MainPanel style={{ width: '100%' }}>
            <BaseWrapper.Text
              width={'70%'}
              margin={'0 auto'}
            ></BaseWrapper.Text>
            <BaseWrapper.Container
              width={'100%'}
              height={'88px'}
              borderRadius={'20px'}
            ></BaseWrapper.Container>
            <BaseWrapper.Container
              width={'100%'}
              height={'88px'}
              borderRadius={'20px'}
            ></BaseWrapper.Container>
            <BaseWrapper.Container
              width={'50%'}
              height={'50px'}
              margin={'0 auto'}
              borderRadius={'6px'}
            ></BaseWrapper.Container>
          </MainPanel>
        </LPContainer>
        <Shimmer></Shimmer>
      </SkeletonContainer>
    </>
  );
}

export function HomeSkeleton(props) {
  return (
    <>
      <SkeletonContainer>
        <HomScreenContainer>
          <ProductContainer>
            <BaseWrapper.Header margin={'0 auto'}></BaseWrapper.Header>
            <CategoriesWrapper>
              <CategoryBlock>
                <BaseWrapper.Title
                  margin={'30px 0'}
                  maxWidth={'200px'}
                  height={'34px'}
                ></BaseWrapper.Title>
                <BaseWrapper.Container
                  width={'100%'}
                  height={'50px'}
                ></BaseWrapper.Container>
                <MenuItemContainer>
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((val) => (
                    <div key={val}>
                      <BaseWrapper.Container
                        key={val}
                        width={'100%'}
                        height={'120px'}
                        borderRadius={'10px'}
                        margin={'0'}
                      >
                        <BaseWrapper.Text
                          margin={'130px 0 0 0'}
                        ></BaseWrapper.Text>
                      </BaseWrapper.Container>
                    </div>
                  ))}
                </MenuItemContainer>
              </CategoryBlock>
            </CategoriesWrapper>
            <BaseWrapper.CheckoutFooter
              margin={'0 auto'}
            ></BaseWrapper.CheckoutFooter>
          </ProductContainer>
        </HomScreenContainer>
        <Shimmer></Shimmer>
      </SkeletonContainer>
    </>
  );
}

export function ProductSkeleton(props) {
  return (
    <>
      <SkeletonContainer>
        <ProductContainer>
          <BaseWrapper.Header margin={'0 auto'}></BaseWrapper.Header>
          <CategoriesWrapper>
            <CategoryBlock>
              <ProductListView>
                <BaseWrapper.Container
                  width={'100%'}
                  height={'50px'}
                ></BaseWrapper.Container>
                <BaseWrapper.Title
                  margin={'12px 0'}
                  maxWidth={'200px'}
                  height={'30px'}
                ></BaseWrapper.Title>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((val) => (
                  <div key={val}>
                    <BaseWrapper.Container
                      key={val}
                      width={'100%'}
                      height={'120px'}
                      borderRadius={'12px'}
                      margin={'5px 0'}
                    ></BaseWrapper.Container>
                  </div>
                ))}
              </ProductListView>
            </CategoryBlock>
          </CategoriesWrapper>
          <BaseWrapper.CheckoutFooter
            margin={'0 auto'}
          ></BaseWrapper.CheckoutFooter>
        </ProductContainer>
        <Shimmer></Shimmer>
      </SkeletonContainer>
    </>
  );
}

export function AddressBookSkeleton(props) {
  return (
    <>
      <SkeletonContainer>
        <AddressBookContainer>
          <BaseWrapper.Header margin={'0 auto'}></BaseWrapper.Header>
          <GMAPContainer>
            <BaseWrapper.Container
              width={'100%'}
              minHeight={'25vh'}
              margin={'70px 0 0 0'}
              position={'relative'}
              flex={'2'}
            ></BaseWrapper.Container>
            <BaseWrapper.Container
              width={'50%'}
              height={'50px'}
              margin={'20px auto 5px auto'}
              borderRadius={'10px'}
            ></BaseWrapper.Container>
            <BaseWrapper.Container
              width={'80%'}
              height={'2px'}
              margin={'10px auto'}
              borderRadius={'10px'}
            ></BaseWrapper.Container>
            <BaseWrapper.Container
              width={'90%'}
              height={'50px'}
              margin={'5px auto'}
              borderRadius={'8px'}
            ></BaseWrapper.Container>
            <SavedAddresses>
              <SavedAddresses.Body>
                <BaseWrapper.Text width={'20%'}></BaseWrapper.Text>
              </SavedAddresses.Body>
              <BaseWrapper.Container
                width={'100%'}
                height={'100px'}
                borderRadius={'8px'}
              ></BaseWrapper.Container>
            </SavedAddresses>
            <BaseWrapper.Container
              width={'60%'}
              height={'50px'}
              borderRadius={'8px'}
              margin={'0 auto 20px auto'}
            ></BaseWrapper.Container>
          </GMAPContainer>
        </AddressBookContainer>
        <Shimmer></Shimmer>
      </SkeletonContainer>
    </>
  );
}

export function OfferSkeleton(props) {
  return (
    <>
      <SkeletonContainer>
        <BaseWrapper.Container
          width={'100%'}
          height={'150px'}
          borderRadius={'8px'}
          margin={'5px auto'}
          overflowX={'hidden'}
        ></BaseWrapper.Container>
        <Shimmer></Shimmer>
      </SkeletonContainer>
    </>
  );
}
export function OfferTileSkeleton(props) {
  return (
    <>
      <SkeletonContainer>
        <BaseWrapper.Container
          width={'90%'}
          height={'75px'}
          borderRadius={'8px'}
          margin={'5px auto'}
          overflowX={'hidden'}
        ></BaseWrapper.Container>
        <Shimmer></Shimmer>
      </SkeletonContainer>
    </>
  );
}

export function MyOrdersSkeleton(props) {
  return (
    <>
      <SkeletonContainer>
        <ProductContainer>
          <BaseWrapper.Header margin={'0 auto'}></BaseWrapper.Header>
          <CategoriesWrapper>
            <CategoryBlock>
              <ProductListView>
                {/* <BaseWrapper.Container
                  width={'100%'}
                  height={'50px'}
                ></BaseWrapper.Container> */}
                <BaseWrapper.Title
                  margin={'12px 0'}
                  maxWidth={'200px'}
                  height={'30px'}
                ></BaseWrapper.Title>
                <BaseWrapper.Container
                  width={'100%'}
                  height={'120px'}
                  borderRadius={'12px'}
                  margin={'5px 0'}
                ></BaseWrapper.Container>
                <BaseWrapper.Title
                  margin={'12px 0'}
                  maxWidth={'200px'}
                  height={'30px'}
                ></BaseWrapper.Title>
                {[0, 1, 2, 3].map((val) => (
                  <BaseWrapper.Container
                    key={val.toString()}
                    width={'100%'}
                    height={'120px'}
                    borderRadius={'12px'}
                    margin={'5px 0'}
                  ></BaseWrapper.Container>
                ))}
              </ProductListView>
            </CategoryBlock>
          </CategoriesWrapper>
        </ProductContainer>
        <Shimmer></Shimmer>
      </SkeletonContainer>
    </>
  );
}

export function SwipeableProductSkeleton(props) {
  return (
    <>
      <SkeletonContainer>
        <ProductContainer>
          <ProductListView>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((val) => (
              <div key={val}>
                <BaseWrapper.Container
                  key={val}
                  width={'100%'}
                  height={'120px'}
                  borderRadius={'12px'}
                  margin={'5px 0'}
                ></BaseWrapper.Container>
              </div>
            ))}
          </ProductListView>
        </ProductContainer>
        <Shimmer></Shimmer>
      </SkeletonContainer>
    </>
  );
}

export function ScrollProductSkeleton(props) {
  return (
    <>
      <SkeletonContainer>
        <ProductContainer>
          <ProductListView>
            <BaseWrapper.Container
              width={'100%'}
              height={'97px'}
              borderRadius={'12px'}
              margin={'5px 0'}
            ></BaseWrapper.Container>
            {/* <BaseWrapper.Container
              width={'100%'}
              height={'97px'}
              borderRadius={'12px'}
              margin={'5px 0'}
              opacity={0.5}
            ></BaseWrapper.Container> */}
            {/* <BaseWrapper.Container
              width={'100%'}
              height={'97px'}
              borderRadius={'12px'}
              margin={'5px 0'}
              opacity={0.2}
            ></BaseWrapper.Container> */}
          </ProductListView>
        </ProductContainer>
        <Shimmer></Shimmer>
      </SkeletonContainer>
    </>
  );
}
export function PreviousOrdersSkeleton(props) {
  return (
    <>
      <SkeletonContainer>
        <ProductListView
          style={{ display: 'flex', marginTop: '5px', padding: '5px' }}
        >
          <BaseWrapper.Container
            width={'70%'}
            height={'12em'}
            borderRadius={'12px'}
            margin={'5px 10px'}
          ></BaseWrapper.Container>
          <BaseWrapper.Container
            width={'70%'}
            height={'12em'}
            borderRadius={'12px'}
            margin={'5px 10px'}
          ></BaseWrapper.Container>
          <BaseWrapper.Container
            width={'70%'}
            height={'12em'}
            borderRadius={'12px'}
            margin={'5px 10px'}
          ></BaseWrapper.Container>
        </ProductListView>
        <Shimmer></Shimmer>
      </SkeletonContainer>
    </>
  );
}
