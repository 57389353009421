import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import { OrderType, BUSINESS_TYPE } from '../constants/variables';
import { useHistory, useParams } from 'react-router-dom';
import { HOMESCREEN, LANDING, VEIWADDRESS } from '../constants/routes';
import { useQuery, useProductId } from '../hooks';
import usePersistedState from '../helpers/persistent';
import { useRestaurantsInfoContext } from './restaurantInfo';
import { clevertapEventPush, CT_EVENT_IDS } from '../helpers/clevertapUtil';
import { get } from 'lodash';
import { pushHistory } from '../utils/common';

export const OrderTypeContext = createContext({});

export const OrderTypeContextProvider = function ({ children, ...props }) {
  const [subCategoryName, setSubCategoryName] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [subCategoryId, setSubCategoryId] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const productId = useProductId();
  const history = useHistory();
  const [orderType, setOrderType] = usePersistedState(
    null,
    productId,
    'orderType'
  );
  const [previousPage, setPreviousPage] = usePersistedState(
    null,
    productId,
    'previousPage'
  );
  const { appLaunched } = useRestaurantsInfoContext();
  const [metaData, setMetaData] = usePersistedState({}, productId, 'metaData');
  const [userData, setUserData] = usePersistedState({}, productId, 'userData');
  const [paymentData, setPaymentData] = usePersistedState(
    {
      razorpayOrderId: '',
      gupshupOrderId: '',
      paymentAmount: null,
    },
    productId,
    'paymentData'
  );
  const pollTimeout = useRef();
  const [orderDetailsJson, setOrderDetailsJson] = usePersistedState(
    '',
    productId,
    'orderDetailsJson'
  );
  const isPaymentComplete = useRef(false);

  const eventPush = useCallback(
    () =>
      setTimeout(() => {
        let routeLandedOn = get(history, 'location.pathname', '');
        routeLandedOn =
          routeLandedOn.split('/').length == 2 && routeLandedOn.split('/')[1];
        [productId].includes(routeLandedOn) &&
          clevertapEventPush({
            eventId: CT_EVENT_IDS.APP_LAUNCHED,
            ...(routeLandedOn === 'home'
              ? {}
              : {
                  // eventData: {
                  //   'Order type': orderTypeCached.current,
                  // },
                }),
          });
        appLaunched.current = true;
      }, 1000),
    [orderType]
  );

  useEffect(() => {
    !appLaunched.current && eventPush();
  }, []);

  return (
    <OrderTypeContext.Provider
      value={{
        orderType,
        metaData,
        setOrderType,
        setMetaData,
        categoryId,
        setSubCategoryId,
        subCategoryId,
        setCategoryId,
        subCategoryName,
        setSubCategoryName,
        userData,
        setUserData,
        isMenuOpen,
        setIsMenuOpen,
        paymentData,
        setPaymentData,
        pollTimeout,
        orderDetailsJson,
        setOrderDetailsJson,
        isPaymentComplete,
        previousPage,
        setPreviousPage,
      }}
    >
      {children}
    </OrderTypeContext.Provider>
  );
};

export const useOrderTypeContext = function () {
  const { orderType, setOrderType, ...rest } = useContext(OrderTypeContext);
  const { productId } = useParams();
  const history = useHistory();
  const orderTypeQueryParams = useQuery('orderType');

  const { setProductId, businessType } = useRestaurantsInfoContext();
  if (!orderType) {
    switch (orderTypeQueryParams) {
      case OrderType.DELIVERY: {
        setProductId(productId);
        setOrderType(OrderType.DELIVERY);
        if (rest.metaData && rest.metaData.address) {
          setOrderType(OrderType.DELIVERY);
        } else {
          history.push(VEIWADDRESS.replace(':productId', productId));
        }
        break;
      }
      case OrderType.TAKEAWAY: {
        setProductId(productId);
        setOrderType(OrderType.TAKEAWAY);
        break;
      }
      default: {
        setProductId(productId);
        if (businessType === BUSINESS_TYPE.ECOM) {
          setOrderType(OrderType.DELIVERY);
          history.push(HOMESCREEN.replace(':productId', productId));
        } else {
          history.push(LANDING.replace(':productId', productId));
        }
      }
    }
  }
  return { orderType, ...rest };
};
