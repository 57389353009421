import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import jsCookie from 'js-cookie';
import * as ROUTES from './constants/routes';
import { Spinner } from './Components';
import { RestaurantsInfoContext } from './contexts/restaurantInfo';
import { OrderTypeContextProvider } from './contexts/orderTypeContext';

import './app.scss';
import './assets/scss/dashboardStyle.scss';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerComponent from './Components/Spinner/spinner';

const LandingPage = React.lazy(() => import('./pages/LandingPage/LandingPage'));
const HomeScreen = React.lazy(() => import('./pages/HomeScreen/HomeScreen'));
const SearchResults = React.lazy(() =>
  import('./pages/SearchResults/SearchResults')
);
const Product = React.lazy(() => import('./pages/ProductV2/ProductV2'));
const ViewDeal = React.lazy(() => import('./pages/ViewDeal/ViewDeal'));
const Page404 = React.lazy(() => import('./pages/page404/Page404'));
const Page500 = React.lazy(() => import('./pages/page500/Page500'));
const Profile = React.lazy(() => import('./pages/Profile/Profile'));
const MyOrdersPage = React.lazy(() => import('./pages/MyOrders/MyOrdersPage'));
const FAQ = React.lazy(() => import('./pages/FAQ/FAQ'));
const TERMSANDCONDITIONS = React.lazy(() =>
  import('./pages/Terms and Conditions/TermsandConditions')
);
const OrderPlacement = React.lazy(() =>
  import('./pages/OrderPlacement/OrderPlacement')
);
const ProdDescription = React.lazy(() =>
  import('./pages/ProdDescription/ProdDescriptionPage')
);
const StandAloneCheckout = React.lazy(() =>
  import('./pages/StandAloneCheckout/StandAloneCheckout')
);
const AddressBooks = React.lazy(() =>
  import('./pages/AddressBook/AddressBook')
);
const Payment = React.lazy(() => import('./pages/Payment'));

let deferredPrompt;
export default function App() {
  // Start AddToHomeScreen Button in Drawer

  const [installable, setInstallable] = useState(true);
  const [overlayShow, setOverlayShow] = useState(false);
  const [isInstalled, setIsInstalled] = useState(false);
  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      deferredPrompt = e;
      setInstallable(true);
    });

    window.addEventListener('appinstalled', () => {
      console.log('INSTALL: Web Application Successfully!');
    });

    if (window.matchMedia('(display-mode: standalone)').matches) {
      setIsInstalled(true);
    }

    if (!deferredPrompt) setInstallable(false);
  }, []);

  const handleInstallClick = (e) => {
    setOverlayShow(false);
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('Web application is installed');
        setInstallable(false);
      } else {
        console.log('Web application is not installed');
      }
    });
  };

  //End AddToHomeScreen Button in Drawer

  // Set default Cookie || For development purpose only
  if (process.env.REACT_APP_GIP_USERID_COOKIE) {
    jsCookie.set('gipuserid', process.env.REACT_APP_GIP_USERID_COOKIE);
  }

  if (window.location.reload) {
    sessionStorage.removeItem('editOfferCode');
    sessionStorage.removeItem('copiedCode');
    sessionStorage.removeItem('userid');
    sessionStorage.removeItem('isOfferPublic');
    sessionStorage.removeItem('offerfromavailable');
  }

  var initial_url = window.location.href;
  var url = initial_url.split('/');
  if (url[3].includes('GS')) {
    sessionStorage.setItem('userType', 'Merchant');
  } else {
    sessionStorage.setItem('userType', 'Consumer');
  }
  return (
    <BrowserRouter>
      <React.Suspense fallback={<SpinnerComponent isapp={true} />}>
        <RestaurantsInfoContext>
          <OrderTypeContextProvider>
            <Switch>
              <Route
                exact
                path={ROUTES.HOMESCREEN}
                render={() => (
                  <HomeScreen
                    handleInstallClick={handleInstallClick}
                    deferredPrompt={deferredPrompt}
                    installable={installable}
                    setInstallable={setInstallable}
                    overlayShow={overlayShow}
                    setOverlayShow={setOverlayShow}
                    isInstalled={isInstalled}
                    setIsInstalled={setIsInstalled}
                  />
                )}
              />
              <Route
                exact
                path={ROUTES.BASE}
                render={() => {
                  window.location.href = ROUTES.GUPSHUPHOME;
                  return <div />;
                }}
              />
              <Route
                exact
                path={ROUTES.CHECKOUT}
                render={() => <StandAloneCheckout />}
              />
              <Route exact path={ROUTES.PRODUCT} render={() => <Product />} />

              <Route
                exact
                path={ROUTES.SEARCHRESULT}
                render={() => <SearchResults />}
              />

              <Route
                exact
                path={ROUTES.VEIWADDRESS}
                render={() => <AddressBooks addNewAddress={false} />}
              />
              <Route
                exact
                path={ROUTES.LANDING}
                render={
                  () => <LandingPage />
                  // btype === 'ecom' ? (
                  //   // <Redirect to={ROUTES.HOMESCREEN}></Redirect>
                  //   <HomeScreen />
                  // ) : (
                  //   <LandingPage />
                  // )
                }
              />
              <Route exact path={ROUTES.PROFILE} render={() => <Profile />} />
              <Route
                exact
                path={ROUTES.MYORDERS}
                render={() => <MyOrdersPage />}
              />
              <Route exact path={ROUTES.FAQ} render={() => <FAQ />} />
              <Route
                exact
                path={ROUTES.TERMSANDCONDITIONS}
                render={() => <TERMSANDCONDITIONS />}
              />
              <Route
                exact
                path={ROUTES.ORDER_SUMMARY}
                render={() => <OrderPlacement />}
              />
              <Route
                exact
                path={ROUTES.PROD_DESCRIPTION}
                render={() => <ProdDescription />}
              />
              <Route
                exact
                path={ROUTES.VIEW_DEAL}
                render={() => <ViewDeal />}
              />
              <Route exact path={ROUTES.PAYMENT}>
                <Payment />
              </Route>
              <Route
                exact
                path={ROUTES.CHECKOUT_D}
                render={() => <StandAloneCheckout />}
              />
              <Redirect from={ROUTES.HOME_D} to={ROUTES.PRODUCT_D} />
              {/* <Route exact path={ROUTES.ORDERCONFIRMATION} render={() => <OrderConfirmation />}/> */}
              <Route
                exact
                path={ROUTES.ERROR500}
                name='Page 500'
                render={() => <Page500 />}
              />
              <Route
                path={ROUTES.ERROR404}
                name='Page 404'
                render={() => <Page404 />}
              />
              <Route name='Page 404' render={() => <Page404 />} />
            </Switch>
          </OrderTypeContextProvider>
        </RestaurantsInfoContext>
        <ToastContainer
          className={
            sessionStorage.getItem('userType') === 'Merchant'
              ? 'merchantType'
              : ''
          }
        />
      </React.Suspense>
    </BrowserRouter>
  );
}
