import styled from 'styled-components';

export const OfferListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 10px;
  width: 100%;
  min-height: 330px;
  max-height: 500px;
  overflow: hidden;
  text-align: left;
`;

OfferListContainer.Header = styled.div`
  width: 100%;
  font-size: 18px;
  font-weight: var(--font-weight-700);
  color: var(--color-grey);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 2px solid #e4e4e4;
  margin-bottom: 10px;
`;

OfferListContainer.Body = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding-bottom: 100px;
`;

OfferListContainer.ApplyOfferInput = styled.div`
  .editOfferCode {
    color: #c9c0c0;
  }
  width: 100%;
  padding: 17px 5px;
  background: var(--list-background-color);
  border-radius: 12px;
  margin-top: 19px;
  display: flex;
  justify-content: space-between;
  input,
  button {
    background: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    font-size: 14px;
    &:disabled {
      color: #b3b3b3;
    }
  }
  input {
    font-weight: var(--font-weight-500);
    width: 80%;
    font-size: 13px;
    @supports (-webkit-touch-callout: none) {
      font-size: 16px;
    }
    ::placeholder {
      color: var(--color-grey);
      opacity: 0.5;
    }
  }
  button {
    color: var(--default-theme-color);
  }
`;

export const Offer = styled.div`
  padding: 0px 5px;
  border-bottom: 2px solid #e4e4e4;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 250px;
`;

Offer.OfferContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 10px;
`;

Offer.OfferName = styled.div`
  font-weight: var(--font-weight-500);
  color: var(--color-grey);
  padding: 10px 10px;
  background: #ffffff 0% 0%;
  border: 2px dashed var(--default-theme-color);
  border-radius: 8px;
  height: 36px;
  display: flex;
  margin-left: 1%;
  align-items: center;
  font-size: 14px;
  justify-content: center;
`;

Offer.OfferDescription = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: var(--font-weight-500);
  color: var(--color-grey);
  margin-left: 23px;
`;

Offer.ApplyButton = styled.button`
  outline: none;
  border: none;
  box-shadow: none;
  color: var(--default-theme-color);
  font-size: 14px;
  background: transparent;
  font-weight: var(--font-weight-500);
`;

Offer.RemoveButton = styled.button`
  // background: var(--default-theme-color);
  color: var(--default-theme-color);
  box-shadow: none;
  margin-left: 75%;
  height: 36px;
  border: none;
  background: none;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 80%;
  margin-top: 50px;
  align-items: center;
  color: var(--default-theme-color);
`;
