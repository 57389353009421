import styled from 'styled-components';
import styleVals from '../../../constants/styles';

export const OTPVerificationForm = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
margin-top: 14px;
`
export const OTPInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing8};
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
`
export const OTPDigitInput = styled.input`
  height: ${styleVals.measurements.spacing40};
  max-width: ${styleVals.measurements.spacing40};
  text-align: center;
  color: ${styleVals.colors.primaryText};
  outline: none;
  border-radius: ${styleVals.measurements.spacing8};
  background: var(--list-background-color) 0% 0% no-repeat padding-box;
    border: none !important;
    width: 40px !important;
    height: 40px;
    outline: none;
    // @supports (-webkit-touch-callout: none) {
    //   font-size: 16px;
    // }
`

export const PhoneInput = styled.div`
  background: var(--list-background-color) 0% 0% no-repeat padding-box;
  border-radius: 8px;
  height: 50px;
  width: 100%;
  margin: 10px 0;
  .number-input {
    width: 100%;
    background: transparent;
    outline: none;
    border: none;
    padding: 18px;
    color: var(--color-grey);
    font-weight: var(--font-weight-700);
  }
`;

export const CreateOTPForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 18px;
`;

CreateOTPForm.Input = styled.input`
  background: var(--list-background-color) 0% 0% no-repeat padding-box;
  border-radius: 8px;
  height: 44px;
  width: 100%;
  padding: 25px 16px;
  margin: 10px 0;
  outline: none;
  border: none;
  color: var(--color-grey);
  font-weight: var(--font-weight-700);
`;
export const SubmitButton = styled.input`
  background: var(--default-theme-color);
  color: var(--default-button-text-color);
  width: 125px;
  height: 46px;
  border-radius: var(--default-button-border-radius);
  font-size: 16px;
  outline: none;
  border: none;
  font-weight: bold;
  margin-top: 40px;
  &:focus,
  &:hover {
    box-shadow: none;
    color: var(--default-button-text-color);
  }
`;
CreateOTPForm.InputInfo = styled.span`
  color: var(--color-grey);
  // text-align: right;
  font-size: 9px;
  font-weight: var(--font-weight-700);
  position: relative;
  // right: -10px;
  white-space: nowrap;
`;

export const VerifyOTPForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 14px;
  .opt-input {
    background: var(--list-background-color) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    border: none !important;
    width: 40px !important;
    height: 40px;
    outline: none;
  }
`;

VerifyOTPForm.ActionContainer = styled.div`
  width: 100%;
  display: flex;
  max-width: 285px;
  justify-content: space-between;
  @media (max-width: 320px) {
    width: 115%;
    // white-space: nowrap;
  }
`;
export const OtpError = styled.div`
  font-size: 14px;
  color: red;
  font-weight: var(--font-weight-500);
  margin-bottom: 0.5em;
  text-align: center;
`;

export const ChangeNumberButton = styled.button`
  background: transparent;
  color: var(--default-theme-color);
  // width: 125px;
  padding: 0;
  height: 46px;
  margin-top: 40px;
  font-weight: var(--font-weight-700);
  font-size: 14px;
  &:focus,
  &:hover {
    box-shadow: none;
  }
  white-space: nowrap;
  margin-right: 14px;
`;
export const ResendContainer = styled.div`
  width: 100%;
  max-width: 285px;
  display: flex;
  justify-content: space-between;
  color: var(--color-grey);
  font-size: 14px;
  font-weight: var(--font-weight-700);
  margin-top: 15px;
  @media (max-width: 575px) {
    width: 100%;
    white-space: nowrap;
  }
  @media (max-width: 320px) {
    width: 119%;
    white-space: nowrap;
  }
`;
ResendContainer.Button = styled.button`
  background: transparent;
  outline: none;
  border: none;
  color: var(--default-theme-color);
  padding: 0;
  margin: 0;
  &.disabled,
  &:disabled {
    opacity: 0.65;
  }
`;
ResendContainer.Timer = styled.p`
  // margin-left: 6em;
`;

export const OtpVerifyBody = styled.div`
  padding: 40px 20px;
  width: 100%;
  h5 {
    text-align: center;
  }
`;
