import { useRestaurantsInfoContext } from '../../contexts/restaurantInfo';
export default function Currency() {
  const { restaurant } = useRestaurantsInfoContext();
  return (
    <>
      {restaurant?.meta_data?.currency_code ? (
        restaurant?.meta_data?.currency_code
      ) : (
        <>&#8377;</>
      )}
    </>
  );
}
