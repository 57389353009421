const styles = {
  measurements: {
    spacing0: '0px',
    spacing2: '2px',
    spacing4: '4px',
    spacing5: '5px',
    spacing6: '6px',
    spacing8: '8px',
    spacing10: '10px',
    spacing12: '12px',
    spacing14: '14px',
    spacing15: '15px',
    spacing16: '16px',
    spacing17: '17px',
    spacing18: '18px',
    spacing19: '19px',
    spacing20: '20px',
    spacing22: '22px',
    spacing24: '24px',
    spacing26: '26px',
    spacing28: '28px',
    spacing30: '30px',
    spacing32: '32px',
    spacing34: '34px',
    spacing36: '36px',
    spacing38: '38px',
    spacing40: '40px',
    spacing42: '42px',
    spacing44: '44px',
    spacing46: '46px',
    spacing48: '48px',
    spacing50: '50px',
    spacing52: '52px',
    spacing54: '54px',
    spacing56: '56px',
    spacing58: '58px',
    spacing60: '60px',
    spacing62: '62px',
    spacing64: '64px',
    spacing66: '66px',
    spacing68: '68px',
    spacing70: '70px',
    spacing72: '72px',
    spacing74: '74px',
    spacing76: '76px',
    spacing78: '78px',
    spacing80: '80px',
    spacing82: '82px',
    spacing84: '84px',
    spacing86: '86px',
    spacing88: '88px',
    spacing90: '90px',
    spacing92: '92px',
    spacing94: '94px',
    spacing96: '96px',
    spacing98: '98px',
    spacing100: '100px',
    spacing102: '102px',
    spacing104: '104px',
    spacing106: '106px',
    spacing108: '108px',
    spacing110: '110px',
    spacing112: '112px',
    spacing114: '114px',
    spacing116: '116px',
    spacing118: '118px',
    spacing120: '120px',
    spacing122: '122px',
    spacing124: '124px',
    spacing126: '126px',
    spacing128: '128px',
    spacing130: '130px',
    spacing132: '132px',
    spacing134: '134px',
    spacing136: '136px',
    spacing138: '138px',
    spacing140: '140px',
    spacing142: '142px',
    spacing144: '144px',
    spacing146: '146px',
    spacing148: '148px',
    spacing150: '150px',
    spacing152: '152px',
    spacing154: '154px',
    spacing156: '156px',
    spacing158: '158px',
    spacing160: '160px',
    spacing162: '162px',
    spacing164: '164px',
    spacing166: '166px',
    spacing168: '168px',
    spacing170: '170px',
    spacing172: '172px',
    spacing174: '174px',
    spacing176: '176px',
    spacing178: '178px',
    spacing180: '180px',
    spacing182: '182px',
    spacing184: '184px',
    spacing186: '186px',
    spacing188: '188px',
    spacing190: '190px',
    spacing192: '192px',
    spacing194: '194px',
    spacing196: '196px',
    spacing198: '198px',
    spacing200: '200px',
    spacing420: '420px',
    spacing500: '500px',
    spacing550: '550px',
  },
  colors: {
    defaultColor: '#EE2F37',
    // boxShadow: '2px 3px 9px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    boxShadow: '0px 1px 2px #00000026',
    boxShadowHover: '0 7px 14px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)',
    grey: '#808080',
    red: '#dc3545',
    white: '#ffffff',
    lightGrey: '#e7e7e7',
    greyText: '#707070',
    disabledGrey: '#bebebe',
    primaryText: '#3f3f3f',
    primaryBackground: '#f2f2f2',
    borderColor: '112, 112, 112',
    customColor: 'rgb(247, 247, 247)',
  },
  transitions: {
    buttonBoxShadow: '0.3s cubic-bezier(.25,.8,.25,1)',
  },
  breakpoints: {
    mobile: '(max-width: 575px)',
    smallMobile: '(max-width: 375px)',
    narrowMobile: '(max-width: 320px)',
  },
};

export default styles;
