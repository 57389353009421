import React, { useEffect, useState } from 'react';
import jsCookie from 'js-cookie';
import './OfferList.scss';
import { OfferListContainer, Offer } from './styles/OfferList.style';
import { listActiveOffers, checkOfferAvailableStatus } from '../../utils/API';
import { Notification } from '../../helpers/notification/notification';
import {
  DiscountTypes,
  OfferTypes,
  OrderType,
  BUSINESS_TYPE,
} from '../../constants/variables';

import { useOrderTypeContext } from '../../contexts/orderTypeContext';
import { validAlphanumericString } from '../../utils/common';
import { getDescription } from '../../pages/discountOffers/discountOffers';
import ModalV2 from '../ModalV2/Modal';
import NoResult from '../NoResult/NoResult';
import { ReactComponent as NoCoupon } from '../../assets/images/no-coupons.svg';
import { OfferSkeleton } from '../../helpers/SkeletonLoader/SkeletonLoader';

export const checkDiscountAvailable = (menu_id, offer, order, metaData) => {
  return new Promise((resolve, reject) => {
    let data = {
      order_subtotal: order.meta.sub_total,
      metaData: metaData,
      order_mode: order.meta.fulfillment_mode,
    };
    checkOfferAvailableStatus(menu_id, offer.offer_id, data)
      .then((res) => {
        if (res.is_offer_applicable) {
          if (
            order.meta.fulfillment_mode === 'takeaway' &&
            res.offer.discount_type !== 'free_delivery'
          ) {
            res.offer_id = offer.offer_id;
            res.order_subtotal = data.order_subtotal;
            res.min_order_value = offer.min_order_value;
            res.type = res.offer.type ? res.offer.type : DiscountTypes.ITEMLVL;
            res.payment_mode = res.offer.payment_mode;
            resolve(res);
          } else if (order.meta.fulfillment_mode === 'delivery') {
            res.offer_id = offer.offer_id;
            res.order_subtotal = data.order_subtotal;
            res.min_order_value = offer.min_order_value;
            res.type = res.offer.type ? res.offer.type : DiscountTypes.ITEMLVL;
            res.payment_mode = res.offer.payment_mode;
            resolve(res);
          } else {
            Notification({
              type: 'error',
              displayMessage:
                'Coupon code invalid for ' + data.order_mode + ' ' + 'mode ',
              toasterOptions: {
                toastId: 'coupon-available',
              },
            });
          }
        } else if (!res.is_offer_applicable) {
          if (res.message) {
            Notification({
              type: 'error',
              displayMessage: res.message,
            });
          } else {
            Notification({
              type: 'error',
              displayMessage: 'Coupon usage limit exceeded',
            });
          }

          sessionStorage.removeItem('editOfferCode');
        } else {
          Notification({
            type: 'error',
            displayMessage: 'Invalid Coupon',
          });
          resolve({});
        }
      })
      .catch((err) => {
        resolve(err);
        sessionStorage.removeItem('editOfferCode');
      });
  });
};
export const ListActiveOffers = (
  menu_id,
  orderType,
  metaData,
  paymentOptions,
  order
) => {
  let type = sessionStorage.getItem(`${menu_id}-btype`);
  let bType = type ? JSON.parse(type) : null;
  let gipuserid = jsCookie.get('gipuserid');
  return new Promise((resolve, reject) => {
    listActiveOffers(menu_id, {
      subTotal: order ? order.meta.sub_total : 0,
      order_mode: orderType,
      deliveryCharge: '',
      loggedIn: !!gipuserid,
    })
      .then((res) => {
        let offers = [];
        res.applicableOffers.forEach(({ offer }) => {
          if (
            offer.is_public &&
            ((offer.discount_type === OfferTypes.FREEDELIVERY &&
              orderType === OrderType.DELIVERY &&
              (bType === BUSINESS_TYPE.ECOM ||
                parseInt(metaData.deliveryCharge) >= 0)) ||
              offer.discount_type === OfferTypes.PERCENT ||
              offer.discount_type === OfferTypes.FLAT ||
              offer.discount_type === OfferTypes.FREEPRODUCT) &&
            ((offer.expire_date &&
              offer.expire_date >= new Date().setHours(0, 0, 0, 0)) ||
              !offer.expire_date)
          ) {
            if (!offer.description) {
              offer.description = getDescription(offer);
            }
            if (
              paymentOptions &&
              offer.payment_mode &&
              ((paymentOptions.cash && offer.payment_mode.cash) ||
                (paymentOptions.online && offer.payment_mode.online))
            ) {
              offers.push(offer);
            } else if (!paymentOptions || !offer.payment_mode) {
              offers.push(offer);
            }
          }
        });
        resolve(offers);
      })
      .catch((e) => {
        console.log(e);
      });
  });
};
export default function OfferList({
  menu_id,
  restaurantColor,
  addOfferCodeandCharges,
  order,
  offers,
  removeOffer,
  funApplyCoupon,
  offersArray: offersArrayDefault,
  offersArrayNonPublic,
  cartItemChanged,
}) {
  const [offersArray, setOffersArray] = useState(offersArrayDefault);
  const [loading, setLoading] = useState(true);
  const [isOfferApplied, setIsOfferApplied] = useState(
    !!sessionStorage.getItem('isOfferApplied')
  );
  const { metaData } = useOrderTypeContext();
  const [couponCode, setCouponCode] = useState(
    sessionStorage.getItem('editOfferCode') || ''
  );
  useEffect(() => {
    setLoading(false);
  }, [offersArrayDefault]);

  // const generateDescription = (offer) => {
  //   let description = '';
  //   switch (offer.discount_type) {
  //     case OfferTypes.FLAT:
  //       description = <b>Get ₹{offer.discount_value} off</b>;
  //       break;
  //     case OfferTypes.PERCENT:
  //       description = <b>Get {offer.discount_value}% off </b>;
  //       break;
  //     case OfferTypes.FREEDELIVERY:
  //       description = <b>Get free delivery </b>;
  //       break;
  //   }
  //   return <CCardText>{description}</CCardText>;
  // };

  const updateIsOfferApplied = (offerApplied) => {
    setIsOfferApplied(offerApplied);
    sessionStorage.setItem('isOfferApplied', offerApplied ? 'true' : '');
  };

  const checkAvailable = (menu_id, couponCode, order) => {
    const offer = offersArrayNonPublic.find(
      (offer) => offer.code?.toUpperCase() === couponCode?.toUpperCase()
    );
    if (!offer) {
      Notification({
        type: 'error',
        displayMessage:
          'Invalid coupon code, Please enter a valid coupon code.',
        toasterOptions: {
          toastId: 'coupon-available',
        },
      });
      sessionStorage.removeItem('editOfferCode');
      return;
    }

    if (parseInt(order.meta.sub_total) < parseInt(offer.min_order_value)) {
      Notification({
        type: 'error',
        displayMessage:
          'Order amount is less than minimum order value to apply offer',
        toasterOptions: {
          toastId: 'coupon-available',
        },
      });
      return;
    }

    checkDiscountAvailable(menu_id, offer, order, metaData)
      .then((res) => {
        addOfferCodeandCharges(res);
        if (res.is_offer_applicable) {
          cartItemChanged.current = false;
          // clevertapEventPush({
          //   eventId: CT_EVENT_IDS.COUPON_APPLIED,
          //   eventData: {
          //     Code: get(offer, 'code', ''),
          //     'Maximum discount': get(offer, 'max_discount', ''),
          //     'Applicable discount': get(res, 'offer_discount', ''),
          //     'Original Amount': get(order, 'meta.sub_total', ''),
          //     'Final Amount':
          //       get(order, 'meta.total', '') -
          //       get(offers, 'offer_discount', ''),
          //   },
          // });
          funApplyCoupon();
          updateIsOfferApplied(true);
        } else {
          updateIsOfferApplied(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const setOfferCode = (code) => {
    sessionStorage.setItem('copiedCode', code);
    sessionStorage.setItem('editOfferCode', code);
    updateIsOfferApplied(false);
    // if (code !== '') {
    //   setOffersArray(filteredOffers);
    //   const filtered = offersArray.filter((codedata) => {
    //     if (codedata.code.toLowerCase().includes(code.toLowerCase())) {
    //       return codedata.code.toLowerCase().includes(code.toLowerCase());
    //     } else {
    //       return setOffersArray(filteredOffers);
    //     }
    //   });
    //   setOffersArray(filtered);
    // } else {
    //   setOffersArray(filteredOffers);
    // }
    if (code.length > 10) {
      Notification({
        type: 'error',
        displayMessage: 'Offer code length can not be greater than 10',
        toasterOptions: {
          toastId: 'code-length',
        },
      });
      return;
    }
    if (validAlphanumericString(code) && code.length <= 10) {
      setCouponCode(code.toUpperCase());
    } else {
      Notification({
        type: 'error',
        displayMessage: 'Offer code should be alpha-numeric',
        toasterOptions: {
          toastId: 'offer-text',
        },
      });
    }
  };
  const getOffers = () => {
    return (
      <OfferListContainer>
        <OfferListContainer.Header>
          <span>Coupon Code</span>
          {
            <OfferListContainer.ApplyOfferInput>
              <input
                id='searchInput'
                name='offersCodeInput'
                maxLength='10'
                placeholder='Enter Coupon Code Here'
                defaultValue={sessionStorage.getItem('editOfferCode')}
                autoComplete='off'
                onChange={(e) => setOfferCode(e.target.value)}
                style={{
                  textTransform: 'uppercase',
                  // fontSize: '13px',
                  paddingLeft: '20px',
                }}
              />
              <button
                disabled={!couponCode || isOfferApplied}
                onClick={() => checkAvailable(menu_id, couponCode, order)}
              >
                Apply
              </button>
            </OfferListContainer.ApplyOfferInput>
          }
        </OfferListContainer.Header>

        <OfferListContainer.Body>
          {loading ? <OfferSkeleton /> : null}
          {!loading && offersArray.length === 0 ? (
            <NoResult
              NoResultImg={NoCoupon}
              noResultMsg='No Coupons available'
              noResultAction={funApplyCoupon}
              noResultActionMsg='Back to Checkout'
              fullHeight='true'
              noMargin={true}
            ></NoResult>
          ) : null}
          {offersArray.map((offer, index) => {
            if (offer.is_custom === true) {
              var customdesc = offer.description;
            } else if (offer.is_custom === false) {
              var cut = offer.description.split(' ');
              var defdesc = (
                <div>
                  {cut.map((word, idx) => {
                    if ([2, 5, 6].includes(idx)) {
                      return (
                        <span style={{ color: restaurantColor }}>
                          {' '}
                          {word + ' '}{' '}
                        </span>
                      );
                    } else {
                      return word + ' ';
                    }
                  })}
                  {/* {cut[0] + ' ' + cut[1]}
                  <span style={{ color: restaurantColor }}> {cut[2]} </span>
                  {cut[3] + ' ' + cut[4]}
                  <span style={{ color: restaurantColor }}>
                    {' '}
                    {cut[5] + ' ' + cut[6]}{' '}
                  </span>
                  {cut[7] +
                    ' ' +
                    cut[8] +
                    ' ' +
                    cut[9] +
                    ' ' +
                    cut[10] +
                    ' ' +
                    cut[11] +
                    ' ' +
                    cut[12] +
                    ' ' +
                    cut[13] +
                    ' '}
                  {cut[14] && cut[14]} */}
                </div>
              );
            }
            return (
              <Offer key={index}>
                <Offer.OfferContainer>
                  <Offer.OfferName>{offer.code}</Offer.OfferName>
                  <Offer.OfferDescription>
                    {customdesc}
                    {defdesc}
                  </Offer.OfferDescription>
                </Offer.OfferContainer>

                <Offer.OfferContainer>
                  {offers.offer_id !== offer.offer_id ? (
                    <Offer.ApplyButton
                      onClick={() => checkAvailable(menu_id, offer.code, order)}
                      disabled={
                        parseInt(order.meta.sub_total) <
                        parseInt(offer.min_order_value)
                          ? true
                          : false
                      }
                      className={
                        parseInt(order.meta.sub_total) <
                        parseInt(offer.min_order_value)
                          ? 'Disabled'
                          : ''
                      }
                    >
                      Apply
                    </Offer.ApplyButton>
                  ) : (
                    <Offer.RemoveButton
                      onClick={() => {
                        updateIsOfferApplied(false);
                        removeOffer();
                      }}
                    >
                      Remove
                    </Offer.RemoveButton>
                  )}
                </Offer.OfferContainer>
              </Offer>
            );
          })}
        </OfferListContainer.Body>
      </OfferListContainer>
    );
  };
  return (
    <ModalV2
      onCloseButtonClick={funApplyCoupon}
      ModalBody={getOffers()}
      position='bottom'
      theme='dark'
      offerlist={true}
    ></ModalV2>
  );
}
