import { useContext } from 'react';
import {
  ModalContainer,
  CloseButton,
  BodyContainer,
} from './style/Modal.style';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import { RestaurantsInfo } from '../../contexts/restaurantInfo';
export default function ModalV2({
  ModalBody,
  onCloseButtonClick,
  position,
  theme,
  variantLength,
  offerlist,
}) {
  const { restaurant } = useContext(RestaurantsInfo);
  const restaurantColor = restaurant?.meta_data?.uiColor;
  return (
    <ModalContainer position={position} theme={theme}>
      <CloseButton
        className='c-pointer'
        onClick={() => onCloseButtonClick()}
        restaurantColor={restaurantColor}
      >
        {/* <img src={close} alt='close'></img> */}
        <Close className='popUpCloseBtn' />
      </CloseButton>
      <BodyContainer
        offerlist={offerlist}
        className={variantLength >= 7 ? 'variants' : ''}
        position={position}
      >
        {ModalBody}
      </BodyContainer>
    </ModalContainer>
  );
}
