import styled from 'styled-components';

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 500px;
  height: 100%;
  background: rgba(255, 255, 255);
  background: ${(props) =>
    props.theme === 'dark'
      ? 'rgba(0, 0, 0, 0.58)'
      : 'rgba(255, 255, 255, 0.89)'};
  z-index: 11;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.position === 'bottom' ? 'flex-end' : 'center'};
  align-items: center;
  .variants {
    max-height: 540px;
    .variant_groups {
      height: 100%;
      overflow: scroll;
      max-height: 352px;
      @media (max-width: 767px) {
        max-height: 280px;
      }
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;

export const CloseButton = styled.button`
  width: 53px;
  height: 53px;
  border-radius: 50%;
  outline: none;
  border: none;
  background: var(--default-backdrop-color);
  color: var(--default-theme-color);
  .popUpCloseBtn {
    height: 22px;
    width: 22px;
    pointer-events: none;
    .cls-1 {
      fill: ${({ restaurantColor }) =>
        restaurantColor ? restaurantColor : 'none'};
      stroke: ${({ restaurantColor }) =>
        restaurantColor ? restaurantColor : '#b14141'};
      stroke-linecap: round;
      stroke-width: 2px;
    }
  }
`;
export const BodyContainer = styled.div`
  overflow: ${(props) => props.offerlist && 'hidden'};
  ::-webkit-scrollbar {
    display: none;
  }
  height: 100%;
  max-height: 428px;
  display: block;
  margin-top: 30px;
  width: 90%;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 30px #a7a7a761;
  ${(props) => {
    return props.position === 'bottom'
      ? `
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  `
      : `border-radius: 20px;`;
  }}
  // display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 40px 20px; */
`;
