import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import jsCookie from 'js-cookie';
import { useOrderTypeContext } from '../contexts/orderTypeContext';
import usePersistedState from '../helpers/persistent';
import { ChargeTypes, DiscountTypes, OrderType } from '../constants/variables';
import {
  updateCartDetails as updateCartDetailsAPI,
  getCartDetails,
} from '../utils/API';
import { checkDiscountAvailable } from '../Components/OfferList/OfferList';
import { Notification } from '../helpers/notification/notification';
import { cleverTapEventAdd } from '../utils/common';

const ProductCartHook = (products, setVariantsAllProduct) => {
  const {
    orderType,
    metaData,
    categoryId,
    subCategoryId,
    setSubCategoryId,
    setCategoryId,
  } = useOrderTypeContext();
  const { productId } = useParams();
  let gipuserid = jsCookie.get('gipuserid');
  const [cart, setCart] = usePersistedState([], productId, 'cart');
  const [offers, setOffers] = usePersistedState({}, productId, 'offers');
  const [totalNoOfItems, setTotalNoOfItems] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalMrpPrice, setTotalMrpPrice] = useState(0);
  const [instructions, setInstructions] = useState('');
  const [orderJson, setOrderJson] = useState();
  const tableReservationTime = {};

  useEffect(() => {
    const noOfItems = cart.reduce((a, b) => a + b.cartcount, 0);
    const totalPrice = cart.reduce(
      (a, b) =>
        a +
        (b.price || b?.variant_groups[0]?.selectedVariant?.price || 0) *
          b.cartcount,
      0
    );
    const totalMrpPrice = cart.reduce(
      (a, b) =>
        a +
        (b.mrp_price ||
          b.price ||
          (b?.variant_groups &&
            b?.variant_groups[0]?.selectedVariant?.mrp_price) ||
          0) *
          b.cartcount,
      0
    );
    setTotalMrpPrice(totalMrpPrice);
    setTotalNoOfItems(noOfItems);
    setTotalPrice(totalPrice);
  }, [cart, setCart]);

  useEffect(() => {
    if (products) {
      createOrderJson();
    }
  }, [offers]);

  useEffect(() => {
    gipuserid && getCartDetails(productId).then(setCart);
  }, []);
  //Cart operations

  const updateCartDetails = (payload) => {
    if (gipuserid) {
      return updateCartDetailsAPI(productId, payload);
    }
  };

  function minusMenuCount(id) {
    let cloncart = [...cart];
    cloncart.forEach((item, i) => {
      if (item.ref_id === id) {
        item.cartcount--;
        updateCartDetails({
          item: item,
          action: 'remove',
          quantity: 1,
          itemId: item.ref_id,
          variantId:
            item.variant_groups &&
            item.variant_groups[0] &&
            item.variant_groups[0].selectedVariant.ref_id,
        });
        if (item.cartcount < 1) {
          updateCartDetails({
            item: item,
            action: 'delete',
            quantity: 1,
            itemId: item.ref_id,
            variantId:
              item.variant_groups &&
              item.variant_groups[0] &&
              item.variant_groups[0].selectedVariant.ref_id,
          });
          return cloncart.splice(i, 1);
        }
      }
    });
    checkOfferAvailable(productId, cloncart);
    setCart(cloncart);
  }

  function plyusMenuCount(id) {
    let cloncart = [...cart];
    cloncart.forEach((item) => {
      if (item.ref_id === id) {
        item.cartcount++;
        updateCartDetails({
          item: item,
          action: 'add',
          quantity: 1,
          itemId: item.ref_id,
          variantId:
            item.variant_groups &&
            item.variant_groups[0] &&
            item.variant_groups[0].selectedVariant.ref_id,
        });
      }
    });
    checkOfferAvailable(productId, cloncart);
    setCart(cloncart);
  }

  function addCart(product) {
    const cloncart = [...cart];
    updateCartDetails({
      item: { ...product, cartcount: 1 },
      action: 'add',
      quantity: 1,
      itemId: product.ref_id,
      variantId:
        product.variant_groups &&
        product.variant_groups[0] &&
        product.variant_groups[0].selectedVariant.ref_id,
    });
    let prodDetails = products?.categories[product?.category_id];

    cleverTapEventAdd(product, prodDetails);
    cloncart.push({ ...product, cartcount: 1 });
    checkOfferAvailable(productId, cloncart);
    return setCart(cloncart);
  }

  function plyusVarintAddCart(product, variant) {
    let cloncart = [...cart];
    let variantbol = false;
    let addonbol = false;
    let addcart = true;
    for (let i = 0; i < cloncart.length; i++) {
      if (product?.ref_id === cloncart[i].ref_id) {
        let countvariant = 0;
        if (product.variant_groups) {
          for (let j = 0; j < product.variant_groups.length; j++) {
            if (
              variant.ref_id ===
              cloncart[i].variant_groups[j].selectedVariant.ref_id
            ) {
              countvariant++;
            }
          }
          if (countvariant === product.variant_groups.length) {
            variantbol = true;
          }
        }

        if (product.variant_groups && variantbol) {
          if (variant === 'variantgroup') {
            cloncart[i].cartcount += product.cartcount;
          } else {
            cloncart[i].cartcount++;
            updateCartDetails({
              item: cloncart[i],
              action: 'add',
              quantity: 1,
              itemId: cloncart[i].ref_id,
              variantId: variant.ref_id,
            });
          }
          setCart(cloncart);
          checkOfferAvailable(productId, cloncart);
          addcart = false;
          return;
        }
      }
      variantbol = false;
      addonbol = false;
    }
    if (addcart) {
      cloncart.push(product);
      setCart(cloncart);
      checkOfferAvailable(productId, cloncart);
      // setvariantPopup(false);
    }
  }

  function minusVarintAddCart(product, variant) {
    let cloncart = [...cart];
    let variantbol = false;
    let addonbol = false;
    for (let i = 0; i < cloncart.length; i++) {
      if (product.ref_id === cloncart[i].ref_id) {
        let countvariant = 0;
        if (product.variant_groups) {
          for (let j = 0; j < product.variant_groups.length; j++) {
            if (
              variant.ref_id ===
              cloncart[i].variant_groups[j].selectedVariant.ref_id
            ) {
              countvariant++;
            }
          }
          if (countvariant === product.variant_groups.length) {
            variantbol = true;
          }
        }

        if (product.variant_groups && variantbol) {
          cloncart[i].cartcount--;
          updateCartDetails({
            item: cloncart[i],
            action: 'remove',
            quantity: 1,
            itemId: cloncart[i].ref_id,
            variantId: variant.ref_id,
          });
          if (cloncart[i].cartcount < 1) {
            updateCartDetails({
              item: cloncart[i],
              action: 'delete',
              quantity: 1,
              itemId: cloncart[i].ref_id,
              variantId: variant.ref_id,
            });
            cloncart.splice(i, 1);
            setVariantsAllProduct(
              cloncart.filter((a) => a.ref_id === product.ref_id)
            );
          }

          setCart(cloncart);
          checkOfferAvailable(productId, cloncart);
          // setvariantPopup(false);
          return;
        }
      }
      variantbol = false;
      addonbol = false;
    }
  }

  //
  async function checkOfferAvailable(productId, cloncart) {
    var sub_total = 0;
    for (let i = 0; i < cloncart.length; i++) {
      let itm = processCartItems(cloncart[i], products);
      sub_total += parseInt(itm.subtotal);
    }
    let offersdata = offers;
    if (offersdata.offer_id && sub_total !== offers.order_subtotal) {
      if (sub_total < offersdata.min_order_value) {
        Notification({
          type: 'error',
          displayMessage: 'Coupon removed for this order',
          toasterOptions: {
            toastId: 'offer-remove',
          },
        });
        setOffers({});
        return;
      }
      checkDiscountAvailable(productId, offersdata, sub_total, metaData)
        .then((res) => {
          setOffers(res);
        })
        .catch((e) => {
          setOffers({});
        });
    } else {
      setOffers({});
    }
  }

  const createOrderJson = () => {
    let ordr = {
      items: [],
      meta: {
        fulfillment_mode: orderType,
        addressReference: metaData ? metaData.addressReference : '',
        table_reservation_time: tableReservationTime,
        // callback_url: `${createCallbackUrl()}`,
        is_edit: 'false',
        restaurant_name:
          products && products.meta_data ? products.meta_data.name : '',
        restaurant_bot:
          products && products.meta_data ? products.meta_data.botname : '',
        instructions: instructions,
        charges: [],
        order_level_charges: 0,
        item_level_charges: 0,
        item_level_taxes: 0,

        discount_code: offers ? (offers.offer_id ? offers.offer_id : '') : '',
        item_level_discount:
          offers &&
          offers.offer_discount &&
          offers.type === DiscountTypes.ITEMLVL
            ? offers.offer_discount
            : 0,
        order_level_discount:
          offers &&
          offers.offer_discount &&
          offers.type === DiscountTypes.ORDERLVL
            ? offers.offer_discount
            : 0,
        sub_total: 0,
        total_charges: 0,
        total_discount: 0,
        total_taxes: 0,
        total: 0,
        ...metaData,
      },
    };
    let items = [];

    let all_bill_components = {
      taxes: {},
      charges: {},
    };
    for (let i = 0; i < cart.length; i++) {
      let itm = processCartItems(cart[i], products, offers);
      items.push(itm);
      for (let j = 0; itm.charges && j < itm.charges.length; j++) {
        let tchrg = itm.charges[j];
        if (all_bill_components.charges[tchrg.title]) {
          all_bill_components.charges[tchrg.title].value += tchrg.value;
        } else {
          all_bill_components.charges[tchrg.title] = tchrg;
        }
        all_bill_components.charges[tchrg.title].value = parseFloat(
          all_bill_components.charges[tchrg.title].value.toFixed(2)
        );
      }
      for (let j = 0; itm.taxes && j < itm.taxes.length; j++) {
        let ttax = itm.taxes[j];
        if (all_bill_components.taxes[ttax.ref_id]) {
          all_bill_components.taxes[ttax.ref_id].value += ttax.value;
        } else {
          all_bill_components.taxes[ttax.ref_id] = ttax;
        }
        all_bill_components.taxes[ttax.ref_id].value = parseFloat(
          all_bill_components.taxes[ttax.ref_id].value.toFixed(2)
        );
      }
      ordr.meta.item_level_charges += itm.totalCharges;
      ordr.meta.item_level_taxes += itm.totalTaxes;
      ordr.meta.sub_total += itm.subtotal;
      ordr.meta.sub_total = parseFloat(ordr.meta.sub_total.toFixed(2));
    }
    ordr.items = items;
    let orderChargeArr = getOrderCharges(
      products,
      ordr.meta.sub_total,
      ordr.meta.item_level_discount
    );

    ordr.meta.charges = orderChargeArr[0];
    for (let j = 0; j < ordr.meta.charges.length; j++) {
      let tchrg = ordr.meta.charges[j];
      all_bill_components.charges[tchrg.title] = tchrg;
    }
    ordr.all_bill_components = all_bill_components;
    ordr.meta.order_level_charges = parseFloat(
      parseFloat(orderChargeArr[1]).toFixed(2)
    );
    ordr.meta.total_charges =
      ordr.meta.item_level_charges + ordr.meta.order_level_charges;
    ordr.meta.item_level_taxes = parseFloat(
      ordr.meta.item_level_taxes.toFixed(2)
    );
    ordr.meta.total_taxes = ordr.meta.item_level_taxes;
    ordr.meta.total_discount =
      ordr.meta.item_level_discount + ordr.meta.order_level_discount;

    ordr.meta.total =
      ordr.meta.sub_total + ordr.meta.total_taxes + ordr.meta.total_charges;
    ordr.meta.total -= ordr.meta.total_discount;
    ordr.meta.total = parseFloat(ordr.meta.total.toFixed(2));

    setOrderJson(ordr);
    return ordr;
  };

  const processCartItems = (cartItm, catalogue, offers) => {
    let itm = {};
    itm.ref_id = cartItm.ref_id;
    itm.title = cartItm.title;
    itm.quantity = cartItm.cartcount;
    itm.discount = 0;
    itm.price_per_unit = cartItm.price;
    let varArr = getSelectedVariant(cartItm.variant_groups);
    itm.variants = varArr[0];
    itm.variantTotal = varArr[1];
    let addonArr = getSelectedAddOns(cartItm.add_on_groups);
    itm.addons = addonArr[0];
    itm.addonTotal = addonArr[1];
    itm.subtotal =
      (itm.price_per_unit + itm.addonTotal + itm.variantTotal) *
      cartItm.cartcount;
    itm.discount =
      offers && offers.offer_discount
        ? offers.offer_discount * (itm.subtotal / offers.order_subtotal)
        : 0;

    let chargeArr = processItemCharges(
      cartItm.bill_components.charges,
      catalogue.bill_components.charges,
      itm.subtotal - itm.discount,
      itm.quantity
    );
    itm.charges = chargeArr[0];
    itm.totalCharges = chargeArr[1];
    let taxArr = processItemTaxes(
      itm.subtotal - itm.discount,
      cartItm.bill_components.taxes,
      catalogue.bill_components.taxes
    );
    itm.taxes = taxArr[0];
    itm.totalTaxes = taxArr[1];
    itm.total = itm.subtotal + taxArr[1] + chargeArr[1];
    return itm;
  };

  function getSelectedVariant(variantGroups) {
    let varnts = [];
    let varTotal = 0;
    if (!variantGroups) {
      return [varnts, varTotal];
    }
    for (let i = 0; i < variantGroups.length; i++) {
      let sel = variantGroups[i].selectedVariant;
      let vr = {};
      vr.ref_id = sel.ref_id;
      vr.title = sel.title;
      vr.price_per_unit = sel.price;
      varTotal += sel.price;
      varnts.push(vr);
    }
    return [varnts, varTotal];
  }
  function getOrderCharges(catalogue, subtotal, item_level_discount) {
    let chrgs = [];
    let totalCharges = 0;
    let allCharges = catalogue.bill_components.charges;
    if (!allCharges) {
      return [chrgs, totalCharges];
    }
    for (
      let i = 0;
      catalogue.order_bill_components &&
      i < catalogue.order_bill_components.length;
      i++
    ) {
      for (let j = 0; j < allCharges.length; j++) {
        if (
          catalogue.order_bill_components[i] === allCharges[j].ref_id &&
          allCharges[j].fulfillment_modes.includes(orderType)
        ) {
          let chrg = {};
          chrg.ref_id = allCharges[j].ref_id;
          chrg.title = allCharges[j].title;
          if (allCharges[j].type === ChargeTypes.FIXED) {
            chrg.value = allCharges[j].value;
          } else if (allCharges[j].type === ChargeTypes.PERCENTAGE) {
            chrg.value =
              parseInt((subtotal - item_level_discount) * allCharges[j].value) /
              100;
            chrg.percentage = allCharges[j].value;
          }
          totalCharges += chrg.value;
          chrgs.push(chrg);
        }
      }
    }
    if (orderType === OrderType.DELIVERY) {
      chrgs.push({
        title: 'Delivery Charges',
        value: metaData
          ? metaData.deliveryCharge
            ? metaData.deliveryCharge
            : 0
          : 0,
        ref_id: 'delivery',
      });
      totalCharges += metaData
        ? metaData.deliveryCharge
          ? metaData.deliveryCharge
          : 0
        : 0;
    }
    return [chrgs, totalCharges];
  }

  function getSelectedAddOns(addOnGroups) {
    let addons = [];
    let addonTotal = 0;
    if (!addOnGroups) {
      return [addons, addonTotal];
    }
    for (let i = 0; i < addOnGroups.length; i++) {
      let sel = addOnGroups[i].selectedAddOn;
      for (let j = 0; j < sel.length; j++) {
        let addn = {};
        addn.ref_id = sel[j].ref_id;
        addn.title = sel[j].title;
        addn.price_per_unit = sel[j].price;
        addonTotal += addn.price_per_unit;
        addons.push(addn);
      }
    }
    return [addons, addonTotal];
  }

  function processItemCharges(itmCharges, allCharges, subtotal, quantity) {
    let charges = [];
    let totalCharges = 0;
    if (!itmCharges || !allCharges) {
      return [charges, totalCharges];
    }
    for (let i = 0; i < itmCharges.length; i++) {
      for (let j = 0; j < allCharges.length; j++) {
        if (
          itmCharges[i] === allCharges[j].ref_id &&
          allCharges[j].fulfillment_modes.includes(orderType)
        ) {
          let chrg = {};
          chrg.ref_id = allCharges[j].ref_id;
          chrg.title = allCharges[j].title;
          chrg.type = allCharges[j].type;
          if (chrg.type === ChargeTypes.FIXED) {
            chrg.value = allCharges[j].value * quantity;
          } else if (chrg.type === ChargeTypes.PERCENTAGE) {
            chrg.value = parseInt(subtotal * allCharges[j].value) / 100;
            chrg.percentage = allCharges[j].value;
          }
          charges.push(chrg);
          totalCharges += chrg.value;
          break;
        }
      }
    }
    return [charges, totalCharges];
  }

  function processItemTaxes(subtotal, itmTaxes, allTaxes) {
    let taxes = [];
    let totalTaxes = 0;
    if (!itmTaxes || !allTaxes) {
      return [taxes, totalTaxes];
    }
    for (let i = 0; i < itmTaxes.length; i++) {
      for (let j = 0; j < allTaxes.length; j++) {
        if (itmTaxes[i] === allTaxes[j].ref_id.toString()) {
          let tax = {};
          tax.ref_id = allTaxes[j].ref_id;
          tax.title = allTaxes[j].title;
          tax.percentage = allTaxes[j].value;
          tax.value = (subtotal * tax.percentage) / 100;
          taxes.push(tax);
          totalTaxes += tax.value;
          break;
        }
      }
    }
    return [taxes, totalTaxes];
  }

  function productAllPrice(product) {
    let price = product?.price;
    if (product?.variant_groups || product?.add_on_groups) {
      if (product.variant_groups) {
        product.variant_groups.forEach(
          (item) => (price += item.selectedVariant.price)
        );
      }

      if (product?.add_on_groups) {
        for (let i = 0; i < product.add_on_groups.length; i++) {
          product.add_on_groups[i].selectedAddOn.forEach(
            (item) => (price += item.price)
          );
        }
      }
      return parseFloat((price * product?.cartcount).toFixed(2));
    } else {
      return parseFloat((product?.price * product?.cartcount).toFixed(2));
    }
  }

  function productPrice(product) {
    let price = product.price;
    if (product.variant_groups || product.add_on_groups) {
      if (product.variant_groups) {
        for (let i = 0; i < product.variant_groups.length; i++) {
          price += product.variant_groups[i].selectedVariant.price;
          price = parseFloat(parseFloat(price).toFixed(2));
        }
      }

      if (product.add_on_groups) {
        for (let i = 0; i < product.add_on_groups.length; i++) {
          product.add_on_groups[i].selectedAddOn.forEach(
            (item) => (price += item.price)
          );
        }
      }
      return price;
    } else {
      return product.price;
    }
  }

  function variantPrice(item) {
    let price = 0;
    for (let i = 0; i < item.variant_groups.length; i++) {
      let defaultVar = item.variant_groups[i].variants
        .sort((a, b) => (a.price > b.price ? 1 : -1))
        .filter((a) => a.in_stock)[0];
      if (defaultVar) {
        price +=
          defaultVar.price !== 0 ? defaultVar.price : defaultVar.mrp_price;
        price = parseFloat(parseFloat(price).toFixed(2));
      }
    }
    return price;
  }

  const productUtils = {
    createOrderJson,
    processCartItems,
    variantPrice,
    productAllPrice,
    productPrice,
  };
  const totalPriceDetails = {
    totalNoOfItems,
    totalPrice,
    totalMrpPrice,
  };
  const cartCore = {
    cart,
    setCart,
    orderJson,
    setOrderJson,
    offers,
    setOffers,
    checkOfferAvailable,

    addCart,
    plyusMenuCount,
    minusMenuCount,
    minusVarintAddCart,
    plyusVarintAddCart,
  };
  return {
    productUtils,
    totalPriceDetails,
    cartCore,
  };
};
export default ProductCartHook;
