import { createContext, useContext, useEffect, useState, useRef } from 'react';
import {
  getRestaurantDetails,
  getMenuDetails,
  getCategoryDetails,
} from '../utils/API';
import { ERROR404, ERROR500 } from '../constants/routes';
import { GlobalStyles } from '../global-styles';
import usePersistedState from '../helpers/persistent';
import { BUSINESS_TYPE, Color, OrderType } from '../constants/variables';
import jsCookie from 'js-cookie';
import { getGipUser } from '../utils/API';
import { get } from 'lodash';
import { clevertapEventPush, CT_EVENT_IDS } from '../helpers/clevertapUtil';
import { getQuoteData as getQuoteDataAPI } from '../utils/API';
export const RestaurantsInfo = createContext(null);

export const RestaurantsInfoContext = function ({ children, ...props }) {
  const [productId, setProductId] = useState(null);
  const [restaurant, setRestaurant] = usePersistedState(
    null,
    productId,
    'restaurant'
  );
  const [businessType, setBusinessType] = usePersistedState(
    '',
    productId,
    'btype'
  );
  const [categoriesData, setCategoriesData] = usePersistedState(
    {},
    productId,
    'categories'
  );

  const [viewOnly, setViewOnly] = usePersistedState(
    false,
    productId,
    'viewOnly'
  );

  const [isServiceable, setServiceable] = usePersistedState(
    null,
    productId,
    'isServiceable'
  );

  const [source, setSource] = usePersistedState(null, 'source');
  const [redirecturl, setRedirecturl] = usePersistedState(null, 'redirecturl');

  const [restaurantColor, setRestaurantColor] = useState(Color.defaultColor);
  const userPhoneNo = useRef('');
  const appLaunched = useRef(false);
  const [getUser, setGetUser] = useState('');

  const setIsStoreServiceable = async function (store, btype) {
    let address = JSON.parse(sessionStorage.getItem(`${productId}-metaData`));
    let orderType = JSON.parse(
      sessionStorage.getItem(`${productId}-orderType`)
    );
    if (orderType === OrderType.TAKEAWAY) {
      setServiceable(true);
    } else if (
      btype === BUSINESS_TYPE.RETAIL &&
      address?.lat &&
      address?.long
    ) {
      const data = {
        restaurantData: {
          lat: store?.meta_data?.address.lat,
          long: store?.meta_data?.address.long,
        },
        address_data: {
          lat: address?.lat,
          long: address?.long,
        },
        restaurant_id: productId,
      };
      getQuoteDataAPI(data)
        .then((quoteData) => {
          setServiceable(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (productId) {
      getRestaurantDetails(productId)
        .then((res) => {
          setRestaurant(res);
          setRestaurantColor(res.meta_data.uiColor);
          // setCatalogue(res);
          let metadata = res;
          setBusinessType(
            res.meta_data?.business_type
              ? res.meta_data?.business_type
              : BUSINESS_TYPE.RETAIL
          );
          setViewOnly(
            !res?.order_types?.delivery?.enabled &&
              !res?.order_types?.takeaway?.enabled
              ? true
              : false
          );
          let btype = res.meta_data?.business_type
            ? res.meta_data?.business_type
            : BUSINESS_TYPE.RETAIL;
          setIsStoreServiceable(res, btype);
          //   getMenuDetails(productId)
          //     .then((res) => {
          //       setCatalogue(res);
          //     })
          //     .catch((error) => console.log(error));
          // })

          getCategoryDetails(productId)
            .then((res) => {
              let categoryObj = {};
              Object.keys(res.categories).forEach((catId) => {
                categoryObj[catId] = {
                  ...res.categories[catId],
                  subcategories: {
                    ...res.categories[catId].subcategories,
                    1: {
                      title: 'All',
                      total_items: res.categories[catId].total_items,
                    },
                  },
                };
              });

              setCategoriesData({
                ...metadata,
                categories: categoryObj,
              });
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 500: {
                window.location.href = ERROR500;
                break;
              }
              case 404: {
                window.location.href = ERROR404;
                break;
              }
              default:
                window.location.href = ERROR500;
            }
          }
        });
    }
  }, [productId]);

  useEffect(() => {
    if (productId) {
      if (!!jsCookie.get('gipuserid')) {
        getGipUser().then((result) => {
          userPhoneNo.current = get(result, 'phone', '');
          clevertapEventPush({
            eventId: CT_EVENT_IDS.LOGIN_PUSH,
            eventData: result,
          });
        });
      }
    }
  }, [productId, getUser]);
  return (
    <RestaurantsInfo.Provider
      value={{
        businessType,
        restaurant,
        setProductId,
        categoriesData,
        restaurantColor,
        setRestaurantColor,
        userPhoneNo,
        appLaunched,
        setGetUser,
        viewOnly,
        setViewOnly,
        isServiceable,
        setServiceable,
        source,
        setSource,
        redirecturl,
        setRedirecturl,
      }}
    >
      <GlobalStyles restaurant={restaurant} />
      {children}
    </RestaurantsInfo.Provider>
  );
};
export function useRestaurantsInfoContext() {
  const {
    restaurant,
    restaurantColor,
    setRestaurantColor,
    productId,
    setProductId,
    setRestaurant,
    categoriesData,
    setCategoriesData,
    businessType,
    setBusinessType,
    userPhoneNo,
    appLaunched,
    setGetUser,
    viewOnly,
    setViewOnly,
    isServiceable,
    setServiceable,
    source,
    setSource,
    redirecturl,
    setRedirecturl,
  } = useContext(RestaurantsInfo);
  return {
    productId,
    restaurantColor,
    setRestaurantColor,
    restaurant,
    setProductId,
    setRestaurant,
    categoriesData,
    setCategoriesData,
    businessType,
    setBusinessType,
    userPhoneNo,
    appLaunched,
    setGetUser,
    viewOnly,
    setViewOnly,
    isServiceable,
    setServiceable,
    redirecturl,
    setRedirecturl,
    source,
    setSource,
  };
}
