import React, { useEffect, useState, useContext, useCallback } from 'react';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import './otpVerification.scss';
import { generateOTP, updateProfile, validateOTP } from '../../utils/API';
import {
  defaultWaitingTimeForResendOTP,
  reCAPTCHA_site_key,
} from '../../constants/variables';
import {
  specialCharactersRestriction,
  integersRestriction,
} from '../../utils/common';
import { RestaurantsInfo } from '../../contexts/restaurantInfo';
import { OrderTypeContext } from '../../contexts/orderTypeContext';
import jsCookie from 'js-cookie';
import { useCookieAuthListener } from '../../hooks';
import { Notification } from '../../helpers/notification/notification';
import OtpInput from 'react-otp-input';
import { UpdateBot as UpdateBotAPI, getUserStatus } from '../../utils/API';
import {
  PhoneInput,
  CreateOTPForm,
  VerifyOTPForm,
  SubmitButton,
  ResendContainer,
  OtpVerifyBody,
  ChangeNumberButton,
  OtpError,
  OTPVerificationForm,
  OTPInputWrapper,
  OTPDigitInput,
} from './styles/OTP-Verification.style';
import { get, range } from '../../utils/common';
import ModalV2 from '../ModalV2/Modal';
import { clevertapEventPush, CT_EVENT_IDS } from '../../helpers/clevertapUtil';

const ResendOTP = ({ getOTP, restaurantColor, setIsInvalidOTP }) => {
  const [timer, setTimer] = useState(defaultWaitingTimeForResendOTP);
  const [resendOTP, setResendOTP] = useState(false);
  const [enableResendButton, setResendButton] = useState(true);

  useEffect(() => {
    let timerUtil = setTimeout(() => {
      timer > 0 ? setTimer(timer - 1) : setResendOTP(true);
    }, 1000);
    if (timer === 0) {
      setResendButton(false);
    }
    return () => {
      clearTimeout(timerUtil);
    };
  }, [timer]);
  return (
    <ResendContainer>
      <p>
        &nbsp; Not Received? &nbsp;
        <ResendContainer.Button
          disabled={enableResendButton}
          restaurantColor={restaurantColor}
          onClick={(e) => {
            e.preventDefault();
            setIsInvalidOTP(false);
            getOTP({ setTimer, setResendButton });
          }}
        >
          Resend
        </ResendContainer.Button>
      </p>
      {enableResendButton && (
        <ResendContainer.Timer>
          00:{timer > 9 ? timer : `0${timer}`}
        </ResendContainer.Timer>
      )}
    </ResendContainer>
  );
};

export default function OTPVerification({
  notificationListener,
  successCallback,
  showModal,
  setShowModal,
  restaurantColor,
  setIsOTPVerificationForGIP,
  setIsOTPVerificationRequire,
  fromStandAloneCheckout,
  getOfferList,
  setGipuserid,
}) {
  const { restaurant, productId, setGetUser } = useContext(RestaurantsInfo);
  const { orderType } = useContext(OrderTypeContext);
  const [isValidPhoneNumber, setISValidPhoneNumber] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isInvalidOTP, setIsInvalidOTP] = useState(false);
  const [OTP, setOTP] = useState('');
  const { setCookie } = useCookieAuthListener();
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showVerify, setShowVerify] = useState(false);
  const [enableVerifyButton, setEnableVerifyButton] = useState(false);
  const [showWaitText, setShowWaitText] = useState(false);
  const [countryCode, setCountryCode] = useState('');
  const [newUser, setNewUser] = useState();

  useEffect(() => {
    showModal &&
      clevertapEventPush({
        eventId: CT_EVENT_IDS.LOGIN_POPUP_OPENED,
      });
  }, [showModal]);

  function updatePhoneNumber(isValid, phoneNumber, metaData, value) {
    var phoneNum = value.replace(/\s/g, '').substring(1);
    setPhoneNumber(phoneNum);
    setISValidPhoneNumber(isValid);
    setCountryCode(metaData.iso2);
  }

  function handleKeypress(event, setTimer, setResendButton) {
    if (event.key === 'Enter') {
      getOTP({ setTimer, setResendButton });
    }
  }

  function getOTP({ setTimer, setResendButton }) {
    if (
      phoneNumber.length <= 0 ||
      phoneNumber[2] === '0' ||
      specialCharactersRestriction(phoneNumber)
    ) {
      Notification({
        type: 'error',
        displayMessage: 'Invalid phone number, Please try again.',
      });
      return;
    }

    if (name === '') {
      Notification({
        type: 'error',
        displayMessage: 'Please Enter Name',
      });
      return;
    }

    if (!isValidPhoneNumber) {
      Notification({
        type: 'error',
        displayMessage: 'Invalid phone number, Please try again.',
      });
      return;
    }

    setLoading(true);
    var eventName = 'OTP_SENT';
    if (setTimer) {
      setTimer(defaultWaitingTimeForResendOTP);
      eventName = 'OTP_RESENT';
      setResendButton(true);
    }
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute(reCAPTCHA_site_key, { action: 'submit' })
        .then((recaptchaToken) => generateOTP({ phoneNumber, recaptchaToken }))
        .then(() => {
          Notification({
            type: 'success',
            displayMessage: 'OTP has been sent to your number',
          });
          getUserStatus(phoneNumber)
            .then((res) => {
              setNewUser(res);
            })
            .catch((err) => {
              console.log(err);
            });
          clevertapEventPush({
            eventId: CT_EVENT_IDS[eventName],
            eventData: {
              'Country code': countryCode,
              'Phone number': phoneNumber,
            },
          });
          setLoading(false);
          setShowVerify(true);
          setOTP('');
          if (setTimer) {
            setTimer(defaultWaitingTimeForResendOTP);
            setResendButton(true);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    });
  }

  function handleSetOTP(otp) {
    const value = otp.replace(/\D/g, '');
    setOTP(value);
    if (value.length === 6) {
      setEnableVerifyButton(true);
    }
  }

  function handleSetOTPFromIOS(event) {
    const value = event.target.value.replace(/\D/g, '');
    setOTP(value);
    if (value.length === 6) {
      setEnableVerifyButton(true);
    }
  }

  function validateUserOTP() {
    if (OTP.length < 6) {
      Notification({
        type: 'error',
        displayMessage: 'Invalid OTP, Please try again.',
      });
      return;
    }
    setLoading(true);
    let data = {
      userProfileDetails: {
        name: name,
        phone_number: { country_code: countryCode, value: phoneNumber },
      },
    };
    validateOTP({ phoneNumber, OTP })
      .then((res) => {
        if (res.status && res.status === 'error') {
          setLoading(false);
          clevertapEventPush({
            eventId: CT_EVENT_IDS.OTP_VERIFICATION,
            eventData: {
              'Country code': countryCode,
              'Phone number': phoneNumber,
              Status: 'Failure',
            },
          });
          setIsInvalidOTP(true);
          return;
        }
        document.cookie = res;
        // jsCookie.set(
        //   'gipuserid',
        //   'Da/8w6CRhsWDOHghRJ46mU9Z9Ljgmbn4nbAGrM+enXKE8MZGaXY4qn9B6SvC89uq'
        // );
        clevertapEventPush({
          eventId: CT_EVENT_IDS.OTP_VERIFICATION,
          eventData: {
            'Country code': countryCode,
            'Phone number': phoneNumber,
            Status: 'Success',
          },
        });
        if (newUser) {
          clevertapEventPush({
            eventId: CT_EVENT_IDS.LOGIN,
            eventData: {
              name: name,
              'Country Code': countryCode,
              phone: phoneNumber,
            },
          });
        } else if (newUser === false) {
          clevertapEventPush({
            eventId: CT_EVENT_IDS.SIGN_UP,
            eventData: {
              name: name,
              'Country Code': countryCode,
              phone: phoneNumber,
            },
          });
        }

        clevertapEventPush({
          eventId: CT_EVENT_IDS.PROFILE_PUSH,
          eventData: {
            name: name,
            phone: phoneNumber,
          },
        });

        if (notificationListener) {
          notificationListener();
        }
        setCookie(jsCookie.get('gipuserid'));
        if (fromStandAloneCheckout) {
          getOfferList();
          setGipuserid(jsCookie.get('gipuserid'));
        }

        updateProfile(data)
          .then((res) => {
            setLoading(false);
            setShowModal(false);
            updateBotType();
            successCallback();
            setGetUser(Math.random());
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((e) => {
        setLoading(false);
        clevertapEventPush({
          eventId: CT_EVENT_IDS.OTP_VERIFICATION,
          eventData: {
            'Country code': countryCode,
            'Phone number': phoneNumber,
            Status: 'Failure',
          },
        });
        setIsInvalidOTP(true);
      });
  }
  const handleModal = () => {
    clevertapEventPush({
      eventId: CT_EVENT_IDS.LOGIN_POPUP_CLOSED,
    });
    setShowModal(false);
    setName('');
    setPhoneNumber('');
    setISValidPhoneNumber(false);
    setShowVerify(false);
    setOTP('');
    setIsOTPVerificationForGIP && setIsOTPVerificationForGIP(false);
    setIsOTPVerificationRequire && setIsOTPVerificationRequire(false);
  };

  const handleNameChange = (e) => {
    if (integersRestriction(e.target.value)) {
    } else {
      setName(e.target.value);
    }
  };

  const handleChangeNumber = () => {
    setIsInvalidOTP(false);
    setShowVerify(false);
    setPhoneNumber('');
    setOTP('');
    clevertapEventPush({
      eventId: CT_EVENT_IDS.NUMBER_CHANGED,
      eventData: {
        'Country code': countryCode,
        'Phone number': phoneNumber,
      },
    });
    setEnableVerifyButton(false);
  };

  function updateBotType() {
    const cookie = jsCookie.get('gipuserid');
    if (cookie) {
      let data = {
        botname: restaurant.meta_data.botname,
        ordertype: orderType,
        store_name: restaurant.meta_data.business_name,
        business_type: restaurant.meta_data.business_type,
      };
      new Promise((resolve, reject) => {
        UpdateBotAPI(productId, data)
          .then((res) => {
            setLoading(false);
            resolve(res);
          })
          .catch((err) => {
            resolve(err);
          });
      });
      return;
    }
  }

  const createOTPComponent = (
    <CreateOTPForm
      onSubmit={(e) => {
        e.preventDefault();
        getOTP(e);
      }}
    >
      <CreateOTPForm.Input
        type='text'
        placeholder='Name'
        value={name}
        maxLength={32}
        onChange={handleNameChange}
      />
      <PhoneInput
        restaurantColor={restaurantColor}
        onKeyPress={(event, timer, enableResendButton) =>
          handleKeypress(event, timer, enableResendButton)
        }
      >
        <IntlTelInput
          inputClassName='number-input'
          preferredCountries={['in']}
          placeholder='Phone Number'
          isInvalid={isValidPhoneNumber}
          onPhoneNumberChange={(isValid, phoneNumber, metaData, value) =>
            updatePhoneNumber(isValid, phoneNumber, metaData, value)
          }
        />
      </PhoneInput>
      <CreateOTPForm.InputInfo>
        *6 Digit OTP will be sent to your mobile number for verification
      </CreateOTPForm.InputInfo>
      <SubmitButton
        type='submit'
        value='Send OTP'
        className='btn'
        disabled={!isValidPhoneNumber || loading || phoneNumber.length <= 0}
        restaurantColor={restaurantColor}
      />
    </CreateOTPForm>
  );

  const checkIsIOS = useCallback(() => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone/.test(userAgent) && !window.MSStream) {
      return true;
    }
    return false;
  }, []);

  const OTPInput = ({ OTP, onChange, isError, setOTPError }) => {
    const [otpDigits, setOtpDigits] = useState(
      OTP.length > 0 ? OTP.split('') : ['', '', '', '', '', '']
    );

    useEffect(() => {
      //group the otp and set , otp length runs on verify button validation
      onChange(otpDigits.join());
      //bring next otp box in focus
      document
        .getElementById(`otp-digit-${otpDigits.length}`)
        ?.focus({ preventScroll: true });
    }, [otpDigits]);

    const handleOTPChange = (e, elIndex) => {
      let enteredVal = get(e, 'target.value', '');
      if (elIndex == 0 && enteredVal?.length == 6) {
        //if we tap on otp suggestion and 1st otp box is focused
        handleSetOTPFromIOS(e);
        return;
      }
      if (elIndex > 0 && enteredVal?.length == 2) {
        //when other than 1st otp box is in focus and we tap on suggested otp , then in iphone we get 2 digits, 1st digit is the 1st otp digit , 2nd digit is depending on otp pos in seq
        const transformOtp = [...otpDigits];
        if (transformOtp.length == 0) {
          transformOtp[0] = enteredVal.toString()[0];
          transformOtp[1] = enteredVal.toString()[1];
        } else {
          transformOtp[transformOtp.length] = enteredVal.toString()[1];
        }
        setOtpDigits(transformOtp);
      } else if (elIndex > 0 && enteredVal?.length == 6) {
        //when other than 1st otp box is in focus and 6 digit otp was copy pasted
        setOtpDigits(enteredVal.split(''));
      } else {
        //when otp input box is used systematically
        enteredVal = /^[0-9]{1}$/.test(enteredVal[0]) ? enteredVal[0] : '';
        const currentOTP = [...otpDigits];
        currentOTP[elIndex] = enteredVal;
        setOtpDigits(currentOTP);
        !!isError && setOTPError('');
      }
      if (enteredVal) {
        elIndex !== 5 &&
          document
            .getElementById(`otp-digit-${elIndex + 1}`)
            .focus({ preventScroll: true });
      }
    };

    const handleKeyDown = (e, elIndex) => {
      if (e.key === 'Backspace' && !otpDigits[elIndex]) {
        elIndex !== 0 &&
          document
            .getElementById(`otp-digit-${elIndex - 1}`)
            .focus({ preventScroll: true });
      }
    };

    return (
      <OTPInputWrapper>
        {range(0, 6).map((elIndex) => {
          return (
            <OTPDigitInput
              id={`otp-digit-${elIndex}`}
              key={`otp-digit-${elIndex}`}
              value={otpDigits[elIndex]}
              onChange={(e) => handleOTPChange(e, elIndex)}
              onBlur={() => !!isError && setOTPError('')}
              onKeyDown={(e) => handleKeyDown(e, elIndex)}
              restaurantColor={restaurantColor}
              type='tel'
              isError={isError}
            />
          );
        })}
      </OTPInputWrapper>
    );
  };
  const verifyOTPComponent = (
    <VerifyOTPForm
      onSubmit={(e) => {
        e.preventDefault();
        validateUserOTP(e);
      }}
    >
      {!checkIsIOS() ? (
        <OtpInput
          value={OTP}
          onChange={handleSetOTP}
          numInputs={6}
          isInputNum='true'
          inputStyle='opt-input'
          separator={<span>&nbsp;&nbsp;</span>}
        />
      ) : (
        <OTPVerificationForm>
          <OTPInput
            OTP={OTP}
            onChange={(value) => handleSetOTPFromIOS({ target: { value } })}
          ></OTPInput>
        </OTPVerificationForm>
      )}

      <ResendOTP
        getOTP={getOTP}
        setShowWaitText={setShowWaitText}
        showWaitText={showWaitText}
        setIsInvalidOTP={setIsInvalidOTP}
      />

      <VerifyOTPForm.ActionContainer>
        <ChangeNumberButton
          className='btn'
          onClick={(e) => {
            e.preventDefault();
            handleChangeNumber();
          }}
        >
          Change Number?
        </ChangeNumberButton>
        <SubmitButton
          type='submit'
          value='Verify'
          className='btn'
          disabled={OTP.length < 6}
          restaurantColor={restaurantColor}
        ></SubmitButton>
      </VerifyOTPForm.ActionContainer>
    </VerifyOTPForm>
  );

  const view = showVerify ? verifyOTPComponent : createOTPComponent;
  let modalBody = (
    <OtpVerifyBody>
      <h5>{showVerify ? 'Almost there' : 'Just a step away from ordering'}</h5>
      {isInvalidOTP && (
        <OtpError>Please enter a valid onetime password</OtpError>
      )}
      {view}
    </OtpVerifyBody>
  );
  if (!showModal) {
    return null;
  }
  return (
    <ModalV2
      onCloseButtonClick={() => handleModal()}
      ModalBody={modalBody}
      restaurantColor={restaurantColor}
    ></ModalV2>
  );
}
