import styled from 'styled-components';

export const NoResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0px 90px 0px;
  margin: ${(props) => (props.noMargin ? '' : '166px 0px 220px 0px;')};
  height: ${(props) => (props.fullHeight ? '100%' : '70%')};
`;

NoResultContainer.Img = styled.img`
  // padding: 30px;
`;

NoResultContainer.Text = styled.div`
  font-size: 12px;
  color: #000000;
  opacity: 1;
  font-weight: var(--font-weight-300);
  margin: 12px 0;
`;

NoResultContainer.Button = styled.button`
  background: var(--default-theme-color);
  color: var(--default-button-text-color);
  padding: 9px 16px;
  :hover {
    box-shadow: none;
    color: var(--default-button-text-color);
  }
`;
